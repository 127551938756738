import actions from "./actions";

const initState = {
    refundDataList: [],
    loading: false,
    refundDataListMessage: null,
    refundDataListError: null,
    refundDataList: [],
    showTableBox: false
};

export default function reducer(state = initState, action) {
    switch (action.type) {
        case actions.GET_DATA_LIST_TO_REFUND:
            return {
                ...state,
                loading: true,
                refundDataList: [],
                refundDataListMessage: null,
                refundDataListError: null,
                showTableBox: true
            };
        case actions.GET_DATA_LIST_TO_REFUND_SUCCESS:
            return {
                ...state,
                loading: false,
                refundDataListError: null,
                refundDataListMessage: null,
                refundDataList: action.refundDataList,
                showTableBox: true
            };
        case actions.GET_DATA_LIST_TO_REFUND_FAILED:
            return {
                ...state,
                loading: false,
                refundDataListError: action.refundDataListError,
                refundDataListMessage: null,
                showTableBox: true
            };
        case actions.TO_REFUND_REQUEST:
            return {
                ...state,
                loading: true,
                refundDataListError: null,
                refundDataListMessage: null,
                showTableBox: true
            };
        case actions.TO_REFUND_REQUEST_SUCCESS:
            return {
                ...state,
                loading: false,
                refundDataListError: null,
                refundDataListMessage: action.refundDataListMessage,
                refundDataList: action.refundDataList,
                showTableBox: true
            };
        case actions.TO_REFUND_REQUEST_FAILED:
            return {
                ...state,
                loading: false,
                refundDataListError: action.refundDataListError,
                refundDataListMessage: null,
                showTableBox: true
            };
        case actions.TO_RESET_REFUND:
            return {
                ...state,
                loading: false,
                refundDataListError: null,
                refundDataListMessage: null,
                showTableBox: false
            };
        case actions.TO_RESET_REFUND_MESSAGE:
            return {
                ...state,
                loading: false,
                refundDataListError: null,
                refundDataListMessage: null,
            };
        default:
            return state;
    }
}