import actions from "./actions";

const initState = {
    loading: false,
    Message: null,
    afterPaymentSuccessMessage: null,
    Error: null,
    data: [],
    PaymentId: null,
    trasactionResult: [],
    buttonLoading: false,
    // Payment gateway
    paymentGatewayLoading: false,
    paymentGatewayMessage: null,
    paymentGatewayError: null,
    paymentGateway: []
};

export default function reducer(state = initState, action) {
    switch (action.type) {
        case actions.AMOUNT_DATA:
            return {
                ...state,
                loading: true,
            };
        case actions.AMOUNT_DATA_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.data,
            };
        case actions.AMOUNT_DATA_FAILED:
            return {
                ...state,
                Error: action.error,
            };
        case actions.CREATE_PAYMENT_ID:
            return {
                ...state,
                loading: true,
            };
        case actions.CREATE_PAYMENT_ID_SUCCESS:
            return {
                ...state,
                loading: false,
                PaymentId: action.PaymentId,
            };
        case actions.CREATE_PAYMENT_ID_FAILED:
            return {
                ...state,
                loading: false,
                Error: action.Error,
            };
        case actions.SEND_PAYMENT_DATA:
            return {
                ...state,
                loading: false,
            };
        case actions.SEND_PAYMENT_DATA_SUCCESS:
            return {
                ...state,
                loading: false,
                afterPaymentSuccessMessage: action.Message,
                data: action.data,
                trasactionResult: action.trasactionResult,
            };
        case actions.SEND_PAYMENT_DATA_FAILED:
            return {
                ...state,
                loading: false,
                orderError: action.Error,
                trasactionResult: action.trasactionResult,
            };
        case actions.GET_TRANSACTION_HISTORY:
            return {
                ...state,
                loading: true,
            };
        case actions.GET_TRANSACTION_HISTORY_SUCCESS:
            return {
                ...state,
                loading: false,
                trasactionResult: action.trasactionResult,
            };
        case actions.GET_TRANSACTION_HISTORY_FAILED:
            return {
                ...state,
                Error: action.error,
            };
        case actions.RESET_ADD_TO_FUND_MESSAGE:
            return {
                ...state,
                loading: false,
                Error: null,
                afterPaymentSuccessMessage: null,
                Message: null,
                buttonLoading: false,
            };

        case actions.GET_PAYMENT_GATEWAY_DEALER:
            return {
                ...state,
                paymentGatewayLoading: true,
                paymentGatewayError: null,
                paymentGatewayMessage: null,
            };
        case actions.GET_PAYMENT_GATEWAY_DEALER_SUCCESS:
            return {
                ...state,
                paymentGatewayLoading: false,
                paymentGatewayError: null,
                paymentGateway: action.paymentGateway,
                paymentGatewayMessage: 'Get success'
            };
        case actions.GET_PAYMENT_GATEWAY_DEALER_FAILED:
            return {
                ...state,
                paymentGatewayLoading: false,
                paymentGatewayMessage: null,
                paymentGatewayError: action.Message
            };

        default:
            return state;
    }
}
