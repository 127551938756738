const actions = {
    CHECK_IMEI: 'CHECK_IMEI',
    CHECK_IMEI_SUCCESS: 'CHECK_IMEI_SUCCESS',
    CHECK_IMEI_FAILED: 'CHECK_IMEI_FAILED',
    CLOSE_MODAL: 'CLOSE_MODAL',

    checkImei: (imei) => ({
        type: actions.CHECK_IMEI,
        payload: { imei }
    }),
    checkImeiSuccess: (data) => ({
        type: actions.CHECK_IMEI_SUCCESS,
        data
    }),
    checkImeiFailed: (error) => ({
        type: actions.CHECK_IMEI_FAILED,
        error
    }),

    closeModal: () => ({
        type: actions.CLOSE_MODAL,
    }),
};
export default actions;
