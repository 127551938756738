import { combineReducers } from 'redux';
import App from '@iso/redux/app/reducer';
import Ecommerce from '@iso/redux/ecommerce/reducer';
import ThemeSwitcher from '@iso/redux/themeSwitcher/reducer';
import LanguageSwitcher from '@iso/redux/languageSwitcher/reducer';
import Articles from '@iso/redux/articles/reducers';
// we define our reducer here
import Auth from '@iso/redux/auth/reducer';
import country from '@iso/redux/country/reducer';
import region from '@iso/redux/region/reducer';
import network from '@iso/redux/network/reducer';
import user from '@iso/redux/user/reducer';
import dealer from '@iso/redux/dealer/reducer';
import tariffGroup from '@iso/redux/tariffGroup/reducer';
import plan from '@iso/redux/plan/reducer';
import inventory from '@iso/redux/inventory/reducer';
import verifyDealer from '@iso/redux/verifyDealer/reducer';
import simPurchase from '@iso/redux/simPurchase/reducer';
import orderList from '@iso/redux/orderlist/reducer';
import planList from '@iso/redux/planlist/reducer';
import profileList from '@iso/redux/getProfile/reducer';
import addToCartPlan from '@iso/redux/addToCartPlan/reducer';
import addToFund from '@iso/redux/addToFund/reducer';
import shortCode from '@iso/redux/shortCode/reducer';
import home from '@iso/redux/home/reducer';
import auditReport from '@iso/redux/auditReport/reducer';
import addPackage from '@iso/redux/addPackage/reducer';
import orderReport from '@iso/redux/orderReport/reducer';
import orderRefund from '@iso/redux/orderRefund/reducer';
import trendingPlanReport from '@iso/redux/trendingPlanReport/reducer';
import refundRequest from '@iso/redux/refundRequest/reducer';
import manualTopUp from '@iso/redux/manualTopUp/reducer';
import coupon from '@iso/redux/coupon/reducer';
import aggregator from '@iso/redux/aggregator/reducer';
import trafficPolicy from '@iso/redux/trafficPolicy/reducer';
import topBar from '@iso/redux/topbar/reducer';
import paymentGateway from '@iso/redux/paymentGateway/reducer';
import esimGoPlan from '@iso/redux/esimGoPlan/reducer';
import emailTemplate from '@iso/redux/emailTemplate/reducer';
import banner from '@iso/redux/banner/reducer';

export default combineReducers({
  App,
  ThemeSwitcher,
  LanguageSwitcher,
  Ecommerce,
  Articles,
  Auth,
  country,
  region,
  network,
  user,
  dealer,
  tariffGroup,
  plan,
  inventory,
  verifyDealer,
  simPurchase,
  orderList,
  planList,
  profileList,
  addToCartPlan,
  addToFund,
  shortCode,
  home,
  auditReport,
  addPackage,
  orderReport,
  orderRefund,
  trendingPlanReport,
  refundRequest,
  manualTopUp,
  coupon,
  aggregator,
  trafficPolicy,
  topBar,
  paymentGateway,
  esimGoPlan,
  emailTemplate,
  esimGoPlan,
  banner
});
