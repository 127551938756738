import { all, takeEvery, put, call } from 'redux-saga/effects';
import actions from './actions';
import { addData, getList } from '../../helpers/fackBackend_Helper';

function* viewCartDataResponse() {
    try {
        const response = yield call(getList, '/getCartData');
        if (response.response.Status) {
            yield put(
                actions.viewCartDataSuccessfull(
                    response.response.Data
                )
            );
        } else {
            yield put(actions.viewCartDataFailed());
        }
    } catch (error) {
        yield put(actions.viewCartDataFailed(error));
    }
}

function* updateCartQuantityResponse({ payload: { data } }) {
    try {
        const response = yield call(addData, '/updateCartData', data);
        if (response.response.Status) {
            const responseList = yield call(getList, '/getCartData');
            if (responseList.response.Status) {
                yield put(
                    actions.updateCartQuantitySuccessfull(
                        response.response.Message, responseList.response.Data
                    ));
            } else {
                yield put(actions.viewCartDataFailed(
                    response.response.Message
                ));
            }
        } else {
            yield put(actions.viewCartDataFailed(response.response.Message));
        }
    } catch (error) {
        yield put(actions.viewCartDataFailed(error));
    }
}

function* deleteCartDataResponse({ payload: { cartId } }) {
    try {
        const response = yield call(getList, '/deleteCartData/' + cartId);
        if (response.response.Status) {
            const responseList = yield call(getList, '/getCartData');
            if (responseList.response.Status) {
                yield put(
                    actions.deleteCartSuccessfull(
                        response.response.Message, responseList.response.Data
                    ));
            } else {
                yield put(actions.viewCartDataFailed(
                    response.response.Message
                ));
            }
        } else {
            yield put(actions.viewCartDataFailed(response.response.Message));
        }
    } catch (error) {
        yield put(actions.viewCartDataFailed(error));
    }
}

function* purchasePlanPaymentResponse({ payload: { provideCustomerEmail } }) {
    try {
        const response = yield call(addData, '/createOrder', provideCustomerEmail);
        if (response.response.Status === 1) {
            yield put(
                actions.purchasePlanPaymentSuccess(
                    response.response.Message
                )
            );
        } else if (response.response.Status === 2) {
            yield put(
                actions.purchasePlanPaymentProcessing(
                    response.response.Message, response.response.CronusData
                )
            );
        }
        else {
            yield put(actions.purchasePlanPaymentFailed(response.response.Message));
        }
    } catch (error) {
        yield put(actions.purchasePlanPaymentFailed(error));
    }
}
function* applyCouponResponse({ payload: { data } }) {
    try {
        const response = yield call(addData, '/applyCoupon', data);
        if (response.response[0].Status) {
            yield put(actions.applyCouponSuccess(response.response[0].Message, response.response));
        } else {
            yield put(actions.applyCouponFailed(response.response[0].Message));
        }
    } catch (error) {
        yield put(actions.applyCouponFailed(error));
    }
}



export default function* rootSaga() {
    yield all([takeEvery(actions.VIEW_CART_DATA, viewCartDataResponse)]);
    yield all([takeEvery(actions.UPDATE_CART_QUANTITY, updateCartQuantityResponse)]);
    yield all([takeEvery(actions.DELETE_CART, deleteCartDataResponse)]);
    yield all([takeEvery(actions.PURCHASE_PLAN_PAYMENT, purchasePlanPaymentResponse)]);
    yield all([takeEvery(actions.COUPON_APPLY, applyCouponResponse)]);
}
