const actions = {
    GET_PLAN: "GET_PLAN",
    GET_PLAN_SUCCESS: "GET_PLAN_SUCCESS",
    GET_PLAN_FAILED: "GET_PLAN_FAILED",
    ADD_PLAN: "ADD_PLAN",
    ADD_PLAN_SUCCESS: "ADD_PLAN_SUCCESS",
    ADD_PLAN_FAILED: "ADD_PLAN_FAILED",
    EDIT_PLAN: "EDIT_PLAN",
    EDIT_PLAN_SUCCESS: "EDIT_PLAN_SUCCESS",
    DELETE_PLAN: "DELETE_PLAN",
    DELETE_PLAN_SUCCESS: "DELETE_PLAN_SUCCESS",
    RESET_PLAN: "RESET_PLAN",
    PREPARE_PLAN_FORM: "PREPARE_PLAN_FORM",
    GET_PLAN_DATA: "GET_PLAN_DATA",
    GET_PLAN_DATA_SUCCESS: "GET_PLAN_DATA_SUCCESS",
    GET_PLAN_DATA_FAILED: "GET_PLAN_DATA_FAILED",
    GET_PLAN_DAY: "GET_PLAN_DAY",
    GET_PLAN_DAY_SUCCESS: "GET_PLAN_DAY_SUCCESS",
    GET_PLAN_DAY_FAILED: "GET_PLAN_DAY_FAILED",
    GET_PLAN_REGION: "GET_PLAN_REGION",
    GET_PLAN_REGION_SUCCESS: "GET_PLAN_REGION_SUCCESS",
    GET_PLAN_REGION_FAILED: "GET_PLAN_REGION_FAILED",
    RESET_PLAN_MESSAGE: "RESET_PLAN_MESSAGE",
    ADD_PLAN_TO_CART: "ADD_PLAN_TO_CART",
    ADD_PLAN_TO_CART_SUCCESSFULL: "ADD_PLAN_TO_CART_SUCCESSFULL",
    ADD_PLAN_TO_CART_FAILED: "ADD_PLAN_TO_CART_FAILED",
    CHECK_CART_DATA: "CHECK_CART_DATA",
    CHECK_CART_DATA_SUCCESSFULL: "CHECK_CART_DATA_SUCCESSFULL",
    CHECK_CART_DATA_FAILED: "CHECK_CART_DATA_FAILED",
    GET_CRONUS_INVENTORY_DATA: "GET_CRONUS_INVENTORY_DATA",
    GET_CRONUS_INVENTORY_DATA_SUCCESS: "GET_CRONUS_INVENTORY_DATA_SUCCESS",
    GET_CRONUS_INVENTORY_DATA_FAILED: "GET_CRONUS_INVENTORY_DATA_FAILED",
    GET_TRAFFIC_POLICY: "GET_TRAFFIC_POLICY",
    GET_TRAFFIC_POLICY_SUCCESS: "GET_TRAFFIC_POLICY_SUCCESS",
    GET_TRAFFIC_POLICY_FAILED: "GET_TRAFFIC_POLICY_FAILED",
    CREATE_PLAN_PACKAGE: "CREATE_PLAN_PACKAGE",
    CREATE_PLAN_PACKAGE_SUCCESS: "CREATE_PLAN_PACKAGE_SUCCESS",
    CREATE_PLAN_PACKAGE_FAILED: "CREATE_PLAN_PACKAGE_FAILED",
    CHECK_PLAN_NAME: "CHECK_PLAN_NAME",
    CHECK_PLAN_NAME_SUCCESS: "CHECK_PLAN_NAME_SUCCESS",
    CHECK_PLAN_NAME_FAILED: "CHECK_PLAN_NAME_FAILED",
    PACKAGE_ADD_PAGE: "PACKAGE_ADD_PAGE",
    OPEN_PLAN_MODAL: "OPEN_PLAN_MODAL",
    OPEN_PLAN_MODAL_SUCCESS: "OPEN_PLAN_MODAL_SUCCESS",
    CLOSE_PLAN_MODAL: "CLOSE_PLAN_MODAL",
    CREATE_FAILED_PLAN: "CREATE_FAILED_PLAN",
    CREATE_FAILED_PLAN_SUCCESS: "CREATE_FAILED_PLAN_SUCCESS",
    CREATE_FAILED_PLAN_FAILED: "CREATE_FAILED_PLAN_FAILED",

    GET_ESIM_GO_SKU: "GET_ESIM_GO_SKU",
    GET_ESIM_GO_SKU_SUCCESS: "GET_ESIM_GO_SKU_SUCCESS",
    GET_ESIM_GO_SKU_FAILED: "GET_ESIM_GO_SKU_FAILED",

    GET_TRAFFIC_POLICY_ACTIVE: "GET_TRAFFIC_POLICY_ACTIVE",
    GET_TRAFFIC_POLICY_ACTIVE_SUCCESS: "GET_TRAFFIC_POLICY_ACTIVE_SUCCESS",
    GET_TRAFFIC_POLICY_ACTIVE_FAILED: "GET_TRAFFIC_POLICY_ACTIVE_FAILED",

    getEsimGoSku: (data) => ({
        type: actions.GET_ESIM_GO_SKU,
        payload: { data }
    }),
    getEsimGoSkuSuccess: (data) => ({
        type: actions.GET_ESIM_GO_SKU_SUCCESS,
        payload: { data }
    }),
    getEsimGoSkuFailed: (error) => ({
        type: actions.GET_ESIM_GO_SKU_FAILED,
        payload: { error }
    }),

    openPlanModal: (planID) => ({
        type: actions.OPEN_PLAN_MODAL,
        payload: { planID }
    }),
    openPlanModalSuccess: (data, planID) => ({
        type: actions.OPEN_PLAN_MODAL_SUCCESS,
        data,
        planID
    }),
    closePlanModal: () => ({
        type: actions.CLOSE_PLAN_MODAL
    }),

    getPlan: (filterPlan) => ({
        type: actions.GET_PLAN,
        payload: { filterPlan }
    }),
    getPlanSuccess: (planResult) => ({
        type: actions.GET_PLAN_SUCCESS,
        planResult
    }),
    getPlanFailed: (planError) => ({
        type: actions.GET_PLAN_FAILED,
        planError
    }),
    addPlan: (data, filterPlan) => ({
        type: actions.ADD_PLAN,
        payload: { data, filterPlan }
    }),
    addPlanSuccess: (planMessage, planResult) => ({
        type: actions.ADD_PLAN_SUCCESS,
        planMessage,
        planResult
    }),
    addPlanFailed: (planError) => ({
        type: actions.ADD_PLAN_FAILED,
        planError
    }),
    editPlan: (data) => ({
        type: actions.EDIT_PLAN,
        payload: { data }
    }),
    editPlanSuccess: (planMessage, planResult) => ({
        type: actions.EDIT_PLAN_SUCCESS,
        planMessage,
        planResult
    }),
    deletePlan: (planId, status, filterPlan) => ({
        type: actions.DELETE_PLAN,
        payload: { planId, status, filterPlan }
    }),
    deletePlanSuccess: (planMessage, planResult) => ({
        type: actions.DELETE_PLAN_SUCCESS,
        planMessage,
        planResult
    }),
    preparePlanForm: () => ({
        type: actions.PREPARE_PLAN_FORM
    }),
    resetError: () => ({
        type: actions.RESET_PLAN
    }),
    getPlanData: (data) => ({
        type: actions.GET_PLAN_DATA,
        data
    }),
    getPlanDataSuccess: (planData) => ({
        type: actions.GET_PLAN_DATA_SUCCESS,
        planData
    }),
    getPlanDataFailed: (planError) => ({
        type: actions.GET_PLAN_DATA_FAILED,
        planError
    }),
    getPlanDay: (data) => ({
        type: actions.GET_PLAN_DAY,
        data
    }),
    getPlanDaySuccess: (planDayData) => ({
        type: actions.GET_PLAN_DAY_SUCCESS,
        planDayData
    }),
    getPlanDayFailed: (planError) => ({
        type: actions.GET_PLAN_DAY_FAILED,
        planError
    }),
    getPlanRegion: (data) => ({
        type: actions.GET_PLAN_REGION,
        data
    }),
    getPlanRegionSuccess: (planRegionData) => ({
        type: actions.GET_PLAN_REGION_SUCCESS,
        planRegionData
    }),
    getPlanRegionFailed: (planError) => ({
        type: actions.GET_PLAN_REGION_FAILED,
        planError
    }),
    resetPlanMessage: () => ({
        type: actions.RESET_PLAN_MESSAGE
    }),
    addPlanToCart: (data) => ({
        type: actions.ADD_PLAN_TO_CART,
        payload: { data }
    }),
    addPlanToCartSuccessfull: (planMessage) => ({
        type: actions.ADD_PLAN_TO_CART_SUCCESSFULL,
        planMessage
    }),
    addPlanToCartFailed: (addToCartFailed) => ({
        type: actions.ADD_PLAN_TO_CART_FAILED,
        addToCartFailed
    }),
    checkCartData: (planId) => ({
        type: actions.CHECK_CART_DATA,
        payload: { planId }
    }),
    checkCartDataSuccessfull: (checkCartFlag) => ({
        type: actions.CHECK_CART_DATA_SUCCESSFULL,
        checkCartFlag
    }),
    checkCartDataFailed: (planError) => ({
        type: actions.CHECK_CART_DATA_FAILED,
        planError
    }),
    getCronusInventoryData: () => ({
        type: actions.GET_CRONUS_INVENTORY_DATA,
    }),
    getCronusInventoryDataSuccess: (cronusInventoryData) => ({
        type: actions.GET_CRONUS_INVENTORY_DATA_SUCCESS,
        cronusInventoryData
    }),
    getCronusInventoryDataFailed: (planError) => ({
        type: actions.GET_CRONUS_INVENTORY_DATA_FAILED,
        planError
    }),
    getTrafficPolicy: () => ({
        type: actions.GET_TRAFFIC_POLICY
    }),
    getTrafficPolicySuccess: (trafficPolicyResult) => ({
        type: actions.GET_TRAFFIC_POLICY_SUCCESS,
        trafficPolicyResult
    }),
    getTrafficPolicyFailed: (planError) => ({
        type: actions.GET_TRAFFIC_POLICY_FAILED,
        planError
    }),

    createPlanPackage: (packageData, filterData) => ({
        type: actions.CREATE_PLAN_PACKAGE,
        payload: { packageData, filterData }
    }),
    createPlanPackageSuccess: (packagePlanResult, planResult) => ({
        type: actions.CREATE_PLAN_PACKAGE_SUCCESS,
        packagePlanResult,
        planResult
    }),
    createPlanPackageFailed: (planError) => ({
        type: actions.CREATE_PLAN_PACKAGE_FAILED,
        planError
    }),
    
    checkPlanName: (planName) => ({
        type: actions.CHECK_PLAN_NAME,
        payload: { planName }
    }),
    checkPlanNameSuccess: (message) => ({
        type: actions.CHECK_PLAN_NAME_SUCCESS,
        message
    }),
    checkPlanNameFailed: (error) => ({
        type: actions.CHECK_PLAN_NAME_FAILED,
        error
    }),
    packageAddPage: (packageView) => ({
        type: actions.PACKAGE_ADD_PAGE,
        packageView
    }),
    createFailedPlan: (planData, aggregator, planId, filterPlan) => ({
        type: actions.CREATE_FAILED_PLAN,
        payload: { planData, aggregator, planId, filterPlan }
    }),
    createFailedPlanSuccess: (planMessage, planResult) => ({
        type: actions.CREATE_FAILED_PLAN_SUCCESS,
        planMessage,
        planResult
    }),
    createFailedPlanFailed: (planError) => ({
        type: actions.CREATE_FAILED_PLAN_FAILED,
        planError
    }),
    getTrafficPolicyActive: () => ({
        type: actions.GET_TRAFFIC_POLICY_ACTIVE
    }),
    getTrafficPolicyActiveSuccess: (trafficPolicyResultActive) => ({
        type: actions.GET_TRAFFIC_POLICY_ACTIVE_SUCCESS,
        trafficPolicyResultActive
    }),
    getTrafficPolicyActiveFailed: (planError) => ({
        type: actions.GET_TRAFFIC_POLICY_ACTIVE_FAILED,
        planError
    }),
}
export default actions;