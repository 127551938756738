import { all } from 'redux-saga/effects';
import authSagas from '@iso/redux/auth/saga';
import ecommerceSaga from '@iso/redux/ecommerce/saga';
import articles from '@iso/redux/articles/sagas';
// we define our saga here
import countrySagas from '@iso/redux/country/saga';
import regionSagas from '@iso/redux/region/saga';
import networkSagas from '@iso/redux/network/saga';
import userSagas from '@iso/redux/user/saga';
import dealerSagas from '@iso/redux/dealer/saga';
import tariffGroupSagas from '@iso/redux/tariffGroup/saga';
import planSagas from '@iso/redux/plan/saga';
import inventorySagas from '@iso/redux/inventory/saga';
import verifyDealerSagas from '@iso/redux/verifyDealer/saga';
import SimPurchaseSagas from '@iso/redux/simPurchase/saga';
import orderListSagas from '@iso/redux/orderlist/saga';
import planListSagas from '@iso/redux/planlist/saga';
import profileListSagas from '@iso/redux/getProfile/saga';
import addToCartSagas from '@iso/redux/addToCartPlan/saga';
import addToFundSagas from '@iso/redux/addToFund/saga';
import shortCodeSagas from '@iso/redux/shortCode/saga';
import homeSagas from '@iso/redux/home/saga';
import auditReportSagas from '@iso/redux/auditReport/saga';
import addPackageSagas from '@iso/redux/addPackage/saga';
import orderReportSagas from '@iso/redux/orderReport/saga';
import orderRefundSagas from '@iso/redux/orderRefund/saga';
import trendingPlanReportSagas from '@iso/redux/trendingPlanReport/saga';
import refundRequestSagas from '@iso/redux/refundRequest/saga';
import manualTopUpSagas from '@iso/redux/manualTopUp/saga';
import couponSagas from '@iso/redux/coupon/saga';
import aggregatorSagas from '@iso/redux/aggregator/saga';
import trafficPolicySagas from '@iso/redux/trafficPolicy/saga';
import topBarSagas from '@iso/redux/topbar/saga';
import paymentGatewaySagas from '@iso/redux/paymentGateway/saga';
import esimGoPlanSagas from '@iso/redux/esimGoPlan/saga';
import emailTemplateSagas from '@iso/redux/emailTemplate/saga';
import bannerSagas from '@iso/redux/banner/saga';

export default function* rootSaga(getState) {
  yield all([
    ecommerceSaga(),
    articles(),
    authSagas(),
    countrySagas(),
    regionSagas(),
    networkSagas(),
    userSagas(),
    dealerSagas(),
    tariffGroupSagas(),
    inventorySagas(),
    planSagas(),
    verifyDealerSagas(),
    SimPurchaseSagas(),
    orderListSagas(),
    planListSagas(),
    profileListSagas(),
    addToCartSagas(),
    addToFundSagas(),
    shortCodeSagas(),
    homeSagas(),
    auditReportSagas(),
    addPackageSagas(),
    orderReportSagas(),
    orderRefundSagas(),
    trendingPlanReportSagas(),
    refundRequestSagas(),
    manualTopUpSagas(),
    couponSagas(),
    aggregatorSagas(),
    trafficPolicySagas(),
    topBarSagas(),
    paymentGatewaySagas(),
    esimGoPlanSagas(),
    emailTemplateSagas(),
    esimGoPlanSagas(),
    bannerSagas()
  ]);
}
