import actions from "./actions";

const initState = {
    loading: false,
    error: null,
    message: null,
    esimGoPlan: [],
    esimGoPlanLoading: false,
    esimGoPlanError: null,
    esimGoPlanMessage: null,
};

export default function reducer(state = initState, action) {
    switch (action.type) {
        case actions.GET_ESIM_GO_PLAN:
            return {
                ...state,
                esimGoPlanLoading: true,
                esimGoPlanError: null,
                esimGoPlanMessage: null,
            };

        case actions.GET_ESIM_GO_PLAN_SUCCESS:
            return {
                ...state,
                esimGoPlanLoading: false,
                esimGoPlanError: null,
                esimGoPlan: action.data,
            };

        case actions.GET_ESIM_GO_PLAN_FAILED:
            return {
                ...state,
                esimGoPlanLoading: false,
                esimGoPlanError: action.error,
                esimGoPlanMessage: null,
            };


        case actions.UPLOAD_ESIM_GO_PLAN:
            return {
                ...state,
                loading: true,
                error: null,
                message: null,
            };

        case actions.UPLOAD_ESIM_GO_PLAN_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                message: action.message,
            };

        case actions.UPLOAD_ESIM_GO_PLAN_FAILED:
            return {
                ...state,
                loading: false,
                error: action.error,
                message: null,
            };

        case actions.UPLOAD_ESIM_GO_PLAN_RESET:
            return {
                ...state,
                message: null,
            };

        default:
            return state;
    }
}