import { all, takeEvery, put, fork, call } from 'redux-saga/effects';
import { createBrowserHistory } from 'history';
import jwt_decode from "jwt-decode";

import { getToken, clearToken } from '@iso/lib/helpers/utility';
import actions from './actions';
import { postLogin, addData, getList, postForgetPwd } from '../../helpers/fackBackend_Helper';

const history = createBrowserHistory();
// const fakeApiCall = true; // auth0 or express JWT

export function* loginRequest() {
  yield takeEvery('LOGIN_REQUEST', function* ({ payload: { loginData, token } }) {
    try {
      if (token) {
        yield put({
          type: actions.LOGIN_SUCCESS,
          token: token,
          profile: 'Profile',
        });
      } else {
        const response = yield call(postLogin, '/user_login', loginData);
        if (response.response.Status) {
          yield put({
            type: actions.LOGIN_SUCCESS,
            token: response.response.Token,
            profile: 'Profile',
          });
        } else {
          yield put({
            type: actions.LOGIN_ERROR,
            loginError: response.response.Data.Message
          });
        }
      }
    } catch (error) {
      yield put({
        type: actions.LOGIN_ERROR,
        loginError: error
      });
    }
  });
}

export function* loginSuccess() {
  yield takeEvery(actions.LOGIN_SUCCESS, function* (payload) {
    yield localStorage.setItem('id_token', payload.token);
  });
}

export function* loginError() {
  yield takeEvery(actions.LOGIN_ERROR, function* () { });
}

export function* logout() {
  yield takeEvery(actions.LOGOUT, function* () {
    const token = getToken().get('idToken');
    const data = {
      "token": token,
    }
    const response = yield call(addData, '/user_logout', data);
    if (response.response.Status) {
      yield clearToken();
      history.push('/');
    }
  });
}
export function* checkAuthorization() {
  yield takeEvery(actions.CHECK_AUTHORIZATION, function* () {
    const token = getToken().get('idToken');
    if (token) {
      let decodedToken = jwt_decode(token);
      let currentDate = new Date();
      if (decodedToken.exp * 1000 > currentDate.getTime()) {
        yield put({
          type: actions.LOGIN_SUCCESS,
          token,
          profile: 'Profile',
        });
      } else {
        yield clearToken();
        history.push('/');
      }
    }
  });
}

function* changePasswordResponse({ payload: { passwordData } }) {
  try {
    const response = yield call(addData, '/changePassword', passwordData);
    if (response.response.Status) {
      yield put(actions.changePasswordSuccess(
        response.response.Message
      ));
    }
    else {
      yield put(actions.changePasswordFailed(
        response.response.Message
      ));
    }
  } catch (error) {
    yield put(actions.changePasswordFailed(error));
  }
}

export function* watchChangePasswordResponse() {
  yield takeEvery(actions.CHANGE_PASSWORD, changePasswordResponse)
}

function* userDataByTokenResponse() {
  try {
    const response = yield call(getList, '/getUserByToken');
    if (response.response.Status) {
      yield put(actions.getUserDataByTokenSuccess(
        response.response.Data
      ));
    }
    else {
      yield put(actions.getUserDataByTokenError(
        response.response.Message
      ));
    }
  } catch (error) {
    yield put(actions.getUserDataByTokenError(error));
  }
}

export function* watchUserDataByTokenResponse() {
  yield takeEvery(actions.GET_USER_DATA_BY_TOKEN, userDataByTokenResponse)
}

function* verifyEmailResponse({ payload: { data } }) {
  try {
    const response = yield call(postForgetPwd, '/forgetDealerPassword', data);
    if (response.response.Status) {
      yield put(
        actions.verifyEmailSuccess(
          response.response.Message
        ));
    } else {
      yield put(actions.verifyEmailFailed(response.response.Message));
    }
  } catch (error) {
    yield put(actions.verifyEmailFailed(error));
  }
}

export function* watchVerifyEmailResponse() {
  yield takeEvery(actions.VERIFY_EMAIL_ON_FORGET_PASSOWRD, verifyEmailResponse)
}

function* resetForgetPasswordResponse({ payload: { data } }) {
  try {
    const response = yield call(postForgetPwd, '/resetPassword', data);
    if (response.response.Status) {
      yield put(
        actions.resetForgetPasswordSuccess(
          response.response.Message
        ));
    } else {
      yield put(actions.resetForgetPasswordFailed(response.response.Message));
    }
  } catch (error) {
    yield put(actions.resetForgetPasswordFailed(error));
  }
}

export function* watchResetForgetPasswordResponse() {
  yield takeEvery(actions.RESET_FORGET_PASSWORD, resetForgetPasswordResponse)
}


export default function* rootSaga() {
  yield all([
    fork(checkAuthorization),
    fork(loginRequest),
    fork(loginSuccess),
    fork(loginError),
    fork(logout),
    fork(watchChangePasswordResponse),
    fork(watchUserDataByTokenResponse),
    fork(watchVerifyEmailResponse),
    fork(watchResetForgetPasswordResponse)
  ]);
}
