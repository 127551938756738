import { all, takeEvery, put, call } from 'redux-saga/effects';
import actions from './actions';
import { getList } from '../../helpers/fackBackend_Helper';

function* getPlanListDataResponse() {
    try {
        const response = yield call(getList, '/planlist');
        if (response.response.Status) {
            yield put(
                actions.getPlanListDataSuccess(
                    response.response.Data
                )
            );
        } else {
            yield put(actions.getPlanListDataFailed());
        }
    } catch (error) {
        yield put(actions.getPlanListDataFailed());
    }
}

export default function* rootSaga() {
    yield all([takeEvery(actions.GET_PLAN_LIST_DATA, getPlanListDataResponse)]);
}