import { all, takeEvery, put, call } from 'redux-saga/effects';
import actions from './actions';
import { getList, addData, editData, deleteData } from '../../helpers/fackBackend_Helper';

function* getRegionListResponse() {
    try {
        const response = yield call(getList, '/region');
        if (response.response.Status) {
            yield put(
                actions.getRegionSuccess(
                    response.response.Data
                )
            );
        } else {
            yield put(actions.getRegionFailed());
        }
    } catch (error) {
        yield put(actions.getRegionFailed());
    }
}

function* addRegionResponse({ payload: { data } }) {
    try {
        const response = yield call(addData, '/createRegion', data);
        if (response.response.Status) {
            const responseList = yield call(getList, '/region');
            if (responseList.response.Status) {
                yield put(
                    actions.addRegionSuccess(
                        response.response.Message, responseList.response.Data
                    )
                );
            } else {
                yield put(actions.addRegionFailed(
                    response.response.Message
                ));
            }
        } else {
            yield put(actions.addRegionFailed(
                response.response.Message
            ));
        }
    } catch (error) {
        yield put(actions.addRegionFailed(error));
    }
}

function* editRegionResponse({ payload: { data } }) {
    try {
        const response = yield call(addData, '/updateRegionCountryRelation', data);
        if (response.response.Status) {
            const responseList = yield call(getList, '/region');
            if (responseList.response.Status) {
                yield put(
                    actions.editRegionSuccess(
                        response.response.Message, responseList.response.Data
                    )
                );
            } else {
                yield put(actions.addRegionFailed(
                    response.response.Message
                ));
            }
        } else {
            yield put(actions.addRegionFailed(
                response.response.Message
            ));
        }
    } catch (error) {
        yield put(actions.addRegionFailed(error));
    }
}

function* deleteRegionResponse({ payload: { regionId, status } }) {
    try {
        const response = yield call(deleteData, '/deleteRegion/' + regionId + '/' + status);
        if (response.response.Status) {
            const responseList = yield call(getList, '/region');
            if (responseList.response.Status) {
                yield put(
                    actions.deleteRegionSuccess(
                        response.response.Message, responseList.response.Data
                    )
                );
            } else {
                yield put(actions.getRegionFailed(
                    response.response.Message
                ));
            }
        } else {
            yield put(actions.addRegionFailed(
                response.response.Message
            ));
        }
    } catch (error) {
        yield put(actions.addRegionFailed(error));
    }
}

function* getRegionCountryRelation({ payload: { regionId } }) {
    try {
        const response = yield call(getList, '/regionCountryRelation/' + regionId);
        if (response.response.Status) {
            yield put(
                actions.getCountryRegionRelationSuccessFull(
                    response.response.Data
                )
            );
        } else {
            yield put(actions.getRegionFailed());
        }
    } catch (error) {
        yield put(actions.getRegionFailed());
    }
}

function* getActiveRegionResponse() {
    try {
        const response = yield call(getList, '/getactiveRegion');
        if (response.Status) {
            yield put(actions.getActiveRegionSuccess(response.Regions));
        }
    } catch (error) {
        yield put(actions.getActiveRegionFailed(error));
    }
}

export default function* rootSaga() {
    yield all([takeEvery(actions.GET_REGION, getRegionListResponse)]);
    yield all([takeEvery(actions.ADD_REGION, addRegionResponse)]);
    yield all([takeEvery(actions.DELETE_REGION, deleteRegionResponse)]);
    yield all([takeEvery(actions.EDIT_REGION, editRegionResponse)]);
    yield all([takeEvery(actions.GET_REGION_COUNTRY_RELATION, getRegionCountryRelation)]);
    yield all([takeEvery(actions.GET_ACTIVE_REGION, getActiveRegionResponse)]);
}