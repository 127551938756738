import actions from "./actions";

const initState = {
    orderReportListResult: [],
    loading: false,
    orderReportListError: null,
    orderReportListMessage: null,
    orderReportDetailsResult:[],
    planDetailsReportResult:[],
    orderViewModal: false,
    orderViewModalData: [],

    orderResendMailModal: false,
    resendMailLoading: false,
    resendMailError: null,
    resendMailMessage: null,
    custEmailData: {},
};

export default function reducer(state = initState, action) {
    switch (action.type) {

        case actions.RESET_RESEND_MESSAGE:
            return {
                ...state,
                resendMailLoading: false,
                resendMailError: null,
                resendMailMessage: null,
            };

        case actions.RESEND_MAIL:
            return {
                ...state,
                resendMailLoading: true,
                resendMailError: null,
                resendMailMessage: null,
            };
        case actions.RESEND_MAIL_SUCCESS:
            return {
                ...state,
                resendMailLoading: false,
                resendMailError: null,
                resendMailMessage: action.payload.message,
                orderResendMailModal: false,
                custEmailData: {},
            };
        case actions.RESEND_MAIL_FAILED:
            return {
                ...state,
                resendMailLoading: false,
                resendMailError: action.payload.error,
                resendMailMessage: null,
            };


        case actions.OPEN_RESEND_MAIL_MODAL:
            return {
                ...state,
                orderResendMailModal: true,
                custEmailData: action.payload.data,
            };
        case actions.CLOSE_RESEND_MAIL_MODAL:
            return {
                ...state,
                orderResendMailModal: false,
                custEmailData: {},
            };

        case actions.OPEN_ORDER_MODAL:
            state.orderReportListResult.map((item) => {
                let temp = item;
                if (temp.ID == action.payload.orderID) {
                    temp.loading = !temp.loading;
                }
                return temp;
            });

            return {
                ...state,
            };
        case actions.OPEN_ORDER_MODAL_SUCCESS:
            state.orderReportListResult.map((item) => {
                let temp = item;
                if (temp.ID == action.orderID) {
                    temp.loading = !temp.loading;
                }
                return temp;
            });
            return {
                ...state,
                orderViewModal: true,
                orderViewModalData: action.data
            };

        case actions.CLOSE_ORDER_MODAL:
            return {
                ...state,
                orderViewModalData: [],
            };

        case actions.GET_ORDER_REPORT_LIST:
            return {
                ...state,
                loading: true,
                orderReportListError: null,
                orderReportListMessage: null,
            };
        case actions.GET_ORDER_REPORT_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                orderReportListError: null,
                orderReportListMessage: null,
                orderReportListResult: action.orderReportListResult,
            };
        case actions.GET_ORDER_REPORT_LIST_FAILED:
            return {
                ...state,
                loading: false,
                orderReportListError: action.orderReportListError,
                orderReportListMessage: null,
            };
        case actions.GET_ORDER_REPORT_DETAILS_LIST:
            return {
                ...state,
                loading: true,
                orderReportListError: null,
                orderReportListMessage: null,
            };
        case actions.GET_ORDER_REPORT_DETAILS_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                orderReportListError: null,
                orderReportListMessage: null,
                orderReportDetailsResult: action.orderReportDetailsResult,
                planDetailsReportResult:action.orderReportDetailsResult,
            };
        case actions.GET_ORDER_REPORT_DETAILS_LIST_FAILED:
            return {
                ...state,
                loading: false,
                orderReportListError: action.orderReportListError,
                orderReportListMessage: null,
            };
        default:
            return state;
    }
}