import actions from "./actions";

const initState = {
    dealerResult: [],
    loading: false,
    dealerError: null,
    dealerMessage: null,
    buttonLoading: false,
    dealerFormModal: false,
    dealerLevelResult: [],
    dealerCreationResult: null,
    dealerTariffResult: null,
    dealerLevelObjectResult:{}

};

export default function reducer(state = initState, action) {
    switch (action.type) {
        case actions.GET_DEALER:
            return {
                ...state,
                loading: true,
                dealerError: null,
                dealerMessage: null,
                buttonLoading: false,
                dealerFormModal: false,
            };
        case actions.GET_DEALER_SUCCESS:
            return {
                ...state,
                loading: false,
                dealerError: null,
                dealerMessage: null,
                dealerResult: action.dealerResult,
                buttonLoading: false,
                dealerFormModal: false,
            };
        case actions.GET_DEALER_FAILED:
            return {
                ...state,
                loading: false,
                dealerError: action.dealerError,
                dealerMessage: null,
                buttonLoading: false,
                dealerFormModal: false,
            };
        case actions.ADD_DEALER:
            return {
                ...state,
                loading: true,
                dealerError: null,
                dealerMessage: null,
                buttonLoading: true,
                dealerFormModal: true,
            };
        case actions.ADD_DEALER_SUCCESS:
            return {
                ...state,
                loading: false,
                dealerError: null,
                dealerMessage: action.dealerMessage,
                dealerResult: action.dealerResult,
                buttonLoading: false,
                dealerFormModal: false,
            };
        case actions.ADD_DEALER_FAILED:
            return {
                ...state,
                loading: false,
                dealerError: action.dealerError,
                dealerMessage: null,
                buttonLoading: false,
                dealerFormModal: true,
            };
        case actions.EDIT_DEALER:
            return {
                ...state,
                loading: true,
                dealerError: null,
                dealerMessage: null,
                buttonLoading: true,
                dealerFormModal: true,
            };
        case actions.EDIT_DEALER_SUCCESS:
            return {
                ...state,
                loading: false,
                dealerError: null,
                dealerMessage: action.dealerMessage,
                dealerResult: action.dealerResult,
                buttonLoading: false,
                dealerFormModal: false,
            };
        case actions.DELETE_DEALER:
            return {
                ...state,
                loading: false,
                dealerError: null,
                dealerMessage: null,
                buttonLoading: false,
                dealeroFrmModal: false
            };
        case actions.DELETE_DEALER_SUCCESS:
            return {
                ...state,
                loading: false,
                dealerError: null,
                dealerMessage: action.dealerMessage,
                dealerResult: action.dealerResult,
                buttonLoading: false,
                dealerFormModal: false
            };
        case actions.PREPARE_DEALER_FORM:
            return {
                ...state,
                loading: false,
                dealerMessage: null,
                dealerError: null,
                dealerCreationResult: null,
                buttonLoading: false,
                dealerFormModal: true
            };
        case actions.RESET_DEALER:
            return {
                ...state,
                loading: false,
                dealerMessage: null,
                dealerError: null,
                buttonLoading: false,
                dealerFormModal: false,
                dealerCreationResult: null,
                dealerTariffResult: null,
            };
        case actions.GET_DEALER_LEVEL:
            return {
                ...state
            };
        case actions.GET_DEALER_LEVEL_SUCCESSFULL:
            return {
                ...state,
                loading: false,
                dealerLevelResult: action.dealerLevelResult,
                dealerLevelObjectResult: action.dealerLevelObjectResult
            };
        case actions.GET_DEALER_ALLOW_CREATION:
            return {
                ...state,
                loading: true,
                
            };
        case actions.GET_DEALER_ALLOW_CREATION_SUCCESS:
            return {
                ...state,
                loading: false,
                dealerError: null,
                dealerCreationResult: action.dealerCreationResult,
                buttonLoading: false,
                
            };
        case actions.GET_TARIFF_ALLOW_CREATION:
            return {
                ...state,
                loading: true,

            };
        case actions.GET_TARIFF_ALLOW_CREATION_SUCCESS:
            return {
                ...state,
                loading: false,
                dealerError: null,
                dealerTariffResult: action.dealerTariffResult,
                buttonLoading: false,

            };
            
        case actions.RESET_DEALER_MESSAGE:
            return {
                ...state,
                loading: false,
                dealerMessage: null,
                dealerError: null,
                buttonLoading: false,
                dealerCreationResult: null,
                dealerTariffResult:null,
            };
        default:
            return state;
    }
}
