import { all, takeEvery, put, call } from 'redux-saga/effects';
import actions from './actions';
import { addData, getList, deleteData } from '../../helpers/fackBackend_Helper';

function* getUserListResponse() {
    try {
        const response = yield call(getList, '/users');
        if (response.response.Status) {
            yield put(
                actions.getUserSuccess(
                    response.response.Data
                ));
        } else {
            yield put(actions.getUserFailed(response.response.Message));
        }
    } catch (error) {
        yield put(actions.getUserFailed(error));
    }
}

function* addUserDataResponse({ payload: { data } }) {
    try {
        const response = yield call(addData, '/createUser', data);
        if (response.response.Status) {
            const responseList = yield call(getList, '/users');
            if (responseList.response.Status) {
                yield put(
                    actions.addUserSuccess(
                        response.response.Message, responseList.response.Data
                    ));
            } else {
                yield put(actions.addUserFailed(
                    response.response.Message
                ));
            }
        } else {
            yield put(actions.addUserFailed(response.response.Message));
        }
    } catch (error) {
        yield put(actions.addUserFailed(error));
    }
}

function* editUserDataResponse({ payload: { userId, data } }) {
    try {
        const response = yield call(addData, '/updateUser/' + userId, data);
        if (response.response.Status) {
            const responseList = yield call(getList, '/users');
            if (responseList.response.Status) {
                yield put(
                    actions.editUserSuccess(
                        response.response.Message, responseList.response.Data
                    ));
            } else {
                yield put(actions.addUserFailed(
                    response.response.Message
                ));
            }
        } else {
            yield put(actions.addUserFailed(response.response.Message));
        }
    } catch (error) {
        yield put(actions.addUserFailed(error));
    }
}

function* deleteUserDataResponse({ payload: { userId, status } }) {
    try {
        const response = yield call(deleteData, '/deleteUser/' + userId + '/' + status);
        if (response.response.Status) {
            const responseList = yield call(getList, '/users');
            if (responseList.response.Status) {
                yield put(
                    actions.deleteUserSuccess(
                        response.response.Message, responseList.response.Data
                    ));
            } else {
                yield put(actions.getUserFailed(
                    response.response.Message
                ));
            }
        } else {
            yield put(actions.getUserFailed(response.response.Message));
        }
    } catch (error) {
        yield put(actions.getUserFailed(error));
    }
}

function* resetUserPasswordResponse({ payload: { data } }) {
    try {
        const response = yield call(addData, '/changeUserPassword', data);
        if (response.response.Status) {
            const responseList = yield call(getList, '/users');
            if (responseList.response.Status) {
                yield put(
                    actions.userPasswordResetSuccess(
                        response.response.Message, responseList.response.Data
                    ));
            } else {
                yield put(actions.userPasswordResetFailed(response.response.Message));
            }
        } else {
            yield put(actions.userPasswordResetFailed(response.response.Message));
        }
    } catch (error) {
        yield put(actions.userPasswordResetFailed(error));
    }
}

function* getUserRoleResponse({ payload: { data } }) {
    try {
        const response = yield call(addData, '/shortCode', data);
        if (response.response.Status) {
            yield put(
                actions.getUserRoleSuccess(
                    response.response.Data
                ));
        } else {
            yield put(actions.addUserFailed(response.response.Message));
        }
    } catch (error) {
        yield put(actions.addUserFailed(error));
    }
}

export default function* rootSaga() {
    yield all([takeEvery(actions.GET_USER, getUserListResponse)]);
    yield all([takeEvery(actions.ADD_USER, addUserDataResponse)]);
    yield all([takeEvery(actions.EDIT_USER, editUserDataResponse)]);
    yield all([takeEvery(actions.DELETE_USER, deleteUserDataResponse)]);
    yield all([takeEvery(actions.USER_PASSWORD_RESET, resetUserPasswordResponse)]);
    yield all([takeEvery(actions.GET_USER_ROLE, getUserRoleResponse)]);
}
