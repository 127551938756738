const actions = {
    GET_PLAN_LIST_DATA: "GET_PLAN_LIST_DATA",
    GET_PLAN_LIST_DATA_SUCCESS: "GET_PLAN_LIST_DATA_SUCCESS",
    GET_PLAN_LIST_DATA_FAILED: "GET_PLAN_LIST_DATA_FAILED",

    getPlanListData: () => ({
        type: actions.GET_PLAN_LIST_DATA,
    }),
   getPlanListDataSuccess: (planListDataResult) => ({
        type: actions.GET_PLAN_LIST_DATA_SUCCESS,
       planListDataResult
    }),
    getPlanListDataFailed: (planListDataError) => ({
        type: actions.GET_PLAN_LIST_DATA_FAILED,
        planListDataError
    }),

}
export default actions;