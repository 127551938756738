import { all, takeEvery, put, call } from 'redux-saga/effects';
import actions from './actions';
import { addData } from '../../helpers/fackBackend_Helper';

function* getOrderListResponse({ payload: { data } }) {
    try {
        const response = yield call(addData, '/orderlist', data);
        if (response.response.Status) {
            yield put(
                actions.getOrderListSuccess(
                    response.response.Data
                )
            )
        } else {
            yield put(actions.getOrderListFailed(response.response.Message));
        }
    } catch (error) {
        yield put(actions.getOrderListFailed(error));
    }
}

function* getOrderDetailsResponse({ payload: { orderId } }) {
    try {
        const response = yield call(addData, '/orderdetails/' + orderId);
        if (response.response.Status) {
            yield put(
                actions.getOrderDetailsSuccess(
                    response.response.Data
                )
            )
        } else {
            yield put(actions.getOrderDetailsFailed(response.response.Message));
        }
    } catch (error) {
        yield put(actions.getOrderDetailsFailed(error));
    }
}

function* openOrderModalResponse({ payload: { orderID } }) {
    try {
        const response = yield call(addData, '/orderdetails/' + orderID);
        if (response.response.Status) {
            yield put(actions.openOrderModalSuccess(response.response.Data, orderID));
        } else {
            yield put(actions.closeOrderModal());
        }
    } catch (error) {
        yield put(actions.closeOrderModal());
    }
}

export default function* rootSaga() {
    yield all([takeEvery(actions.GET_ORDER_LIST, getOrderListResponse)]);
    yield all([takeEvery(actions.GET_ORDER_DETAILS_LIST, getOrderDetailsResponse)]);
    yield all([takeEvery(actions.OPEN_ORDER_MODAL, openOrderModalResponse)]);
}