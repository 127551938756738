import actions from "./actions";

const initState = {
    widgetsResult: [],
    loading: false,
    dashboardError: false,
    countryOverview: [],
    inventoryStockResult: []
};

export default function reducer(state = initState, action) {
    switch (action.type) {
        case actions.GET_WIDGETS_DATA:
            return {
                ...state,
                loading: true,
            };
        case actions.GET_WIDGETS_DATA_SUCCESSFULL:
            return {
                ...state,
                loading: false,
                dashboardError: false,
                widgetsResult: action.widgetsResult,
            };
        case actions.GET_WIDGETS_DATA_FAILED:
            return {
                ...state,
                loading: false,
                dashboardError: action.dashboardError,
            };
        case actions.GET_SALES_OVERVIEW_BY_REGION:
            return {
                ...state,
                loading: true,
            };
        case actions.GET_SALES_OVERVIEW_BY_REGION_SUCCESS:
            return {
                ...state,
                loading: false,
                regionSalesResult: action.regionSalesResult
            };
        case actions.GET_PLAN_OVERVIEW_BY_COUNTRY:
            return {
                ...state,
                loading: true,
            };
        case actions.GET_PLAN_OVERVIEW_BY_COUNTRY_SUCCESS:
            return {
                ...state,
                loading: false,
                countryOverview: action.countryOverview
            };
        case actions.GET_INVENTORY_LOW_STOCK_REPORT:
            return {
                ...state,
                loading: true,
            };
        case actions.GET_INVENTORY_LOW_STOCK_REPORT_SUCCESS:
            return {
                ...state,
                loading: false,
                inventoryStockResult: action.inventoryStockResult
            };
        case actions.GET_INVENTORY_LOW_STOCK_REPORT_FAILED:
            return {
                ...state,
                loading: false,
                dashboardError: action.dashboardError
            };
        default:
            return state;
    }
}
