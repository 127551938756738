import { all, takeEvery, put, call } from 'redux-saga/effects';
import actions from './actions';
import { getList, addData, editData, deleteData } from '../../helpers/fackBackend_Helper';

function* updateAggregatorParamsResponse({ payload: { id, data } }) {
    try {
        const response = yield call(addData, '/updateAggregatorParam/' + id, data);
        if (response.Status) {
            yield put(actions.updateAggregatorParamsSuccess(response.Message, response.Message));
            yield put(actions.getAggregator());
        }
    } catch (error) {
        yield put(actions.addAggregatorFailed(error));
    }
}

function* addAggregatorResponse({ payload: { data } }) {
    try {
        const response = yield call(addData, '/addAggregator', data);
        if (response.Status) {
            yield put(actions.addAggregatorSuccess(response.Message, response.Message));
            yield put(actions.getAggregator());
        }
    } catch (error) {
        yield put(actions.addAggregatorFailed(error));
    }
}

function* getAggregatorResponse() {
    try {
        const response = yield call(getList, '/getAggregator');
        if (response.Status) {
            yield put(actions.getAggregatorSuccess(response.Aggregators));
        }
    } catch (error) {
        yield put(actions.addAggregatorFailed(error));
    }
}


function* getActiveAggregatorResponse() {
    try {
        const response = yield call(getList, '/getActiveAggregator');
        if (response.Status) {
            yield put(actions.getActiveAggregatorSuccess(response.Aggregators));
        }
    } catch (error) {
        yield put(actions.getActiveAggregatorFailed(error));
    }
}

export default function* rootSaga() {
    yield all([takeEvery(actions.ADD_AGGREGATOR, addAggregatorResponse)]);
    yield all([takeEvery(actions.GET_AGGREGATOR, getAggregatorResponse)]);
    yield all([takeEvery(actions.UPDATE_AGGREGATOR_PARAMS, updateAggregatorParamsResponse)]);
    yield all([takeEvery(actions.GET_AGGREGATOR_ACTIVE, getActiveAggregatorResponse)]);

}