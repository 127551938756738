const actions = {
    OPEN_ADD_TRAFFIC_POLICY_MODAL: "OPEN_ADD_TRAFFIC_POLICY_MODAL",
    CLOSE_ADD_TRAFFIC_POLICY_MODAL: "CLOSE_ADD_TRAFFIC_POLICY_MODAL",
    ADD_TRAFFIC_POLICY: "ADD_TRAFFIC_POLICY",
    ADD_TRAFFIC_POLICY_SUCCESS: "ADD_TRAFFIC_POLICY_SUCCESS",
    ADD_TRAFFIC_POLICY_FAILED: "ADD_TRAFFIC_POLICY_FAILED",
    GET_TRAFFIC_POLICY_CRONUS: "GET_TRAFFIC_POLICY_CRONUS",
    GET_TRAFFIC_POLICY_CRONUS_SUCCESS: "GET_TRAFFIC_POLICY_CRONUS_SUCCESS",
    GET_TRAFFIC_POLICY_CRONUS_FAILED: "GET_TRAFFIC_POLICY_CRONUS_FAILED",
    TRAFFIC_POLICY_STATUS_CHANGE: "TRAFFIC_POLICY_STATUS_CHANGE",
    TRAFFIC_POLICY_STATUS_CHANGE_SUCCESS: "TRAFFIC_POLICY_STATUS_CHANGE_SUCCESS",
    TRAFFIC_POLICY_STATUS_CHANGE_FAILED: "TRAFFIC_POLICY_STATUS_CHANGE_FAILED",
    UPDATE_TRAFFIC_POLICY: "UPDATE_TRAFFIC_POLICY",
    UPDATE_TRAFFIC_POLICY_SUCCESS: "UPDATE_TRAFFIC_POLICY_SUCCESS",
    UPDATE_TRAFFIC_POLICY_FAILED: "UPDATE_TRAFFIC_POLICY_FAILED",

    updateTrafficPolicy: (data) => ({
        type: actions.UPDATE_TRAFFIC_POLICY,
        payload: { data }
    }),
    updateTrafficPolicySuccess: (message) => ({
        type: actions.UPDATE_TRAFFIC_POLICY_SUCCESS,
        message
    }),
    updateTrafficPolicyFailed: (error) => ({
        type: actions.UPDATE_TRAFFIC_POLICY_FAILED,
        error
    }),

    trafficPolicyStatusChange: (trafficPolicyId,flag) => ({
        type: actions.TRAFFIC_POLICY_STATUS_CHANGE,
        payload: { trafficPolicyId, flag }
    }),
    trafficPolicyStatusChangeSuccess: (message) => ({
        type: actions.TRAFFIC_POLICY_STATUS_CHANGE_SUCCESS,
        message
    }),
    trafficPolicyStatusChangeFailed: (error) => ({
        type: actions.TRAFFIC_POLICY_STATUS_CHANGE_FAILED,
        error
    }),
    getTrafficPolicy: () => ({
        type: actions.GET_TRAFFIC_POLICY_CRONUS,
    }),
    getTrafficPolicySuccess: (data) => ({
        type: actions.GET_TRAFFIC_POLICY_CRONUS_SUCCESS,
        data
    }),
    getTrafficPolicyFailed: (error) => ({
        type: actions.GET_TRAFFIC_POLICY_CRONUS_FAILED,
        error
    }),
    addTrafficPolicy: (data) => ({
        type: actions.ADD_TRAFFIC_POLICY,
        payload: { data }
    }),
    addTrafficPolicySuccess: (message) => ({
        type: actions.ADD_TRAFFIC_POLICY_SUCCESS,
        message
    }),
    addTrafficPolicyFailed: (error) => ({
        type: actions.ADD_TRAFFIC_POLICY_FAILED,
        error
    }),
    openAddTrafficPolicyModal: () => ({
        type: actions.OPEN_ADD_TRAFFIC_POLICY_MODAL,
    }),
    closeAddTrafficPolicyModal: () => ({
        type: actions.CLOSE_ADD_TRAFFIC_POLICY_MODAL,
    }),
    
}
export default actions;