import { all, takeEvery, put, call } from 'redux-saga/effects';
import actions from './actions';
import { getList, addData } from '../../helpers/fackBackend_Helper';

function* getPurchaseListResponse() {
    try {
        const response = yield call(getList, '/purchases');
        // const responseP = yield call(getList, '/prepareInvoice');
        if (response.response.Status) {
            yield put(
                actions.getPurchaseSuccess(
                    response.response.Data
                    //  responseP.response.Data
                )
            );
        } else {
            yield put(actions.getPurchaseFailed());
        }
    } catch (error) {
        yield put(actions.getPurchaseFailed());
    }
}

function* getPreparePurchaseInvoiceResponse() {
    try {
        const response = yield call(getList, '/prepareInvoice');
        if (response.response.Status) {
            yield put(
                actions.prepareAddPurchaseFormSuccess(
                    response.response.Data
                )
            );
        } else {
            yield put(actions.prepareAddPurchaseFormFailed());
        }
    } catch (error) {
        yield put(actions.prepareAddPurchaseFormFailed());
    }
}

function* getPurchaseDetailsResponse({ payload: { purchaseId } }) {
    try {
        const response = yield call(getList, '/purchase/' + purchaseId);
        if (response.response.Status) {
            yield put(
                actions.getPurchaseDetailsSuccess(
                    response.response.Data
                )
            );
        } else {
            yield put(actions.getPurchaseFailed());
        }
    } catch (error) {
        yield put(actions.getPurchaseFailed());
    }
}

function* uploadSimPurchaseDataResponse({ payload: { simDataInfo } }) {
    try {
        const response = yield call(addData, '/validateSimPurchaseData', simDataInfo);
        if (response.response.Status) {
            yield put(
                actions.uploadSimPurchaseSuccess(
                    response.response.Message
                ));
        } else {
            yield put(actions.uploadSimPurchaseFailed(response.response.Message, response.response.simData));
        }
    } catch (error) {
        yield put(actions.getPurchaseFailed(error));
    }
}

function* addPurchaseDataResponse({ payload: { data } }) {
    try {
        const response = yield call(addData, '/createSimPurchase', data);
        if (response.response.Status) {
            const responseList = yield call(getList, '/purchases');
            if (responseList.response.Status) {
                yield put(
                    actions.addPurchaseSuccess(
                        response.response.Message, responseList.response.Data
                    ));
            } else {
                yield put(actions.addPurchaseFailed(
                    response.response.Message
                ));
            }
        } else {
            yield put(actions.addPurchaseFailed(response.response.Message));
        }
    } catch (error) {
        yield put(actions.addPurchaseFailed(error));
    }
}

export default function* rootSaga() {
    yield all([takeEvery(actions.GET_PURCHASE, getPurchaseListResponse)]);
    yield all([takeEvery(actions.PREPARE_ADD_PURCHASE_FORM, getPreparePurchaseInvoiceResponse)]);
    yield all([takeEvery(actions.GET_PURCHASE_DETAILS, getPurchaseDetailsResponse)]);
    yield all([takeEvery(actions.UPLOAD_SIM_PURCHASE, uploadSimPurchaseDataResponse)]);
    yield all([takeEvery(actions.ADD_PURCHASE, addPurchaseDataResponse)]);
}