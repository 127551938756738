import { all, takeEvery, put, call } from 'redux-saga/effects';
import actions from './actions';
import { getList, addData } from '../../helpers/fackBackend_Helper';


function* addTrafficPolicyResponse({ payload: { data } }) {
    try {
        const response = yield call(addData, '/AddTrafficPolicy', data);
        if (response.Status) {
            yield put(actions.addTrafficPolicySuccess(response.Message));
            yield put(actions.getTrafficPolicy());
        }else{
            yield put(actions.addTrafficPolicyFailed(response.Message));
        }
    } catch (error) {
        yield put(actions.addTrafficPolicyFailed(error));
    }
}

function* getTrafficPolicyResponse() {
    try {
        const response = yield call(getList, '/GetTrafficPolicy');
        if (response.Status) {
            yield put(actions.getTrafficPolicySuccess(response.TrafficPolicy));
        }
    } catch (error) {
        yield put(actions.getTrafficPolicyFailed(error));
    }
}

function* updateTrafficPolicyStatusResponse({ payload: { trafficPolicyId, flag } }) {
    try {
        const response = yield call(getList, `/UpdateTrafficPolicyStatus/${trafficPolicyId}/${flag}`);
        if (response.Status) {
            yield put(actions.trafficPolicyStatusChangeSuccess(response.Message));
            yield put(actions.getTrafficPolicy());
        }
    } catch (error) {
        yield put(actions.trafficPolicyStatusChangeFailed(error));
    }
}

function* updateTrafficPolicyResponse({ payload: { data } }) {
    try {
        const response = yield call(addData, `/UpdateTrafficPolicy`, data);
        if (response.Status) {
            yield put(actions.updateTrafficPolicySuccess(response.Message));
            yield put(actions.getTrafficPolicy());
        }
    } catch (error) {
        yield put(actions.updateTrafficPolicyFailed(error));
    }
}



export default function* rootSaga() {
    yield all([takeEvery(actions.ADD_TRAFFIC_POLICY, addTrafficPolicyResponse)]);
    yield all([takeEvery(actions.GET_TRAFFIC_POLICY_CRONUS, getTrafficPolicyResponse)]);
    yield all([takeEvery(actions.TRAFFIC_POLICY_STATUS_CHANGE, updateTrafficPolicyStatusResponse)]);
    yield all([takeEvery(actions.UPDATE_TRAFFIC_POLICY, updateTrafficPolicyResponse)]);

}