const actions = {
    GET_WIDGETS_DATA: 'GET_WIDGETS_DATA',
    GET_WIDGETS_DATA_SUCCESSFULL: 'GET_WIDGETS_DATA_SUCCESSFULL',
    GET_WIDGETS_DATA_FAILED: 'GET_WIDGETS_DATA_FAILED',
    GET_SALES_OVERVIEW_BY_REGION: "GET_SALES_OVERVIEW_BY_REGION",
    GET_SALES_OVERVIEW_BY_REGION_SUCCESS: "GET_SALES_OVERVIEW_BY_REGION_SUCCESS",
    GET_PLAN_OVERVIEW_BY_COUNTRY: "GET_PLAN_OVERVIEW_BY_COUNTRY",
    GET_PLAN_OVERVIEW_BY_COUNTRY_SUCCESS: "GET_PLAN_OVERVIEW_BY_COUNTRY_SUCCESS",
    GET_INVENTORY_LOW_STOCK_REPORT: "GET_INVENTORY_LOW_STOCK_REPORT",
    GET_INVENTORY_LOW_STOCK_REPORT_SUCCESS: "GET_INVENTORY_LOW_STOCK_REPORT_SUCCESS",
    GET_INVENTORY_LOW_STOCK_REPORT_FAILED: "GET_INVENTORY_LOW_STOCK_REPORT_FAILED",

    getWidgetsData: () => ({
        type: actions.GET_WIDGETS_DATA,
    }),
    getWidgetsDataSuccess: (widgetsResult) => ({
        type: actions.GET_WIDGETS_DATA_SUCCESSFULL,
        widgetsResult
    }),
    getWidgetsDataFailed: (dashboardError) => ({
        type: actions.GET_WIDGETS_DATA_FAILED,
        dashboardError
    }),
    getSalesOverviewByRegion: () => ({
        type: actions.GET_SALES_OVERVIEW_BY_REGION,

    }),
    getSalesOverviewByRegionSuccess: (regionSalesResult) => ({
        type: actions.GET_SALES_OVERVIEW_BY_REGION_SUCCESS,
        regionSalesResult
    }),
    getPlanOverviewByCountry: () => ({
        type: actions.GET_PLAN_OVERVIEW_BY_COUNTRY
    }),
    getPlanOverviewByCountrySuccess: (countryOverview) => ({
        type: actions.GET_PLAN_OVERVIEW_BY_COUNTRY_SUCCESS,
        countryOverview
    }),
    getInventoryLowStockReport: () => ({
        type: actions.GET_INVENTORY_LOW_STOCK_REPORT,
    }),
    getInventoryLowStockReportSuccess: (inventoryStockResult) => ({
        type: actions.GET_INVENTORY_LOW_STOCK_REPORT_SUCCESS,
        inventoryStockResult
    }),
    getInventoryLowStockReportFailed: (dashboardError) => ({
        type: actions.GET_INVENTORY_LOW_STOCK_REPORT_FAILED,
        dashboardError
    })
};
export default actions;
