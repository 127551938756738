import { all, takeEvery, put, call } from 'redux-saga/effects';
import actions from './actions';
import { addData } from '../../helpers/fackBackend_Helper';

function* viewPlanDataByICCID({ payload: { searchData } }) {
    try {
        const response = yield call(addData, '/getPlanByICCID', searchData);
        if (response.response.Status) {
            yield put(
                actions.getPlanListByICCIDSuccess(
                    response.response.Data
                )
            );
        } else {
            yield put(actions.getPlanListByICCIDFailed(response.response.Message));
        }
    } catch (error) {
        yield put(actions.getPlanListByICCIDFailed(error));
    }
}

function* purchasePlanPackageResponse({ payload: { rechargePackageData } }) {
    try {
        const response = yield call(addData, '/createRecharge', rechargePackageData);
        if (response.response.Status === 1) {
            yield put(
                actions.purchasePlanPackageSuccess(
                    response.response.Message
                )
            );
        } else if (response.response.Status === 2) {
            yield put(
                actions.purchasePlanPackageProcessing(
                    response.response.Message, response.response.CronusData
                )
            );
        }
        else {
            yield put(actions.purchasePlanPackageFailed(response.response.Message));
        }
    } catch (error) {
        yield put(actions.purchasePlanPackageFailed(error));
    }
}



export default function* rootSaga() {
    yield all([takeEvery(actions.GET_PLAN_LIST_BY_ICCID, viewPlanDataByICCID)]);
    yield all([takeEvery(actions.PURCHASE_PLAN_PACKAGE, purchasePlanPackageResponse)]);
}
