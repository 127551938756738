import { all, takeEvery, put, call } from 'redux-saga/effects';
import actions from './actions';
import { getList, addData, editData, deleteData } from '../../helpers/fackBackend_Helper';

function* updatePaymentGatewayParamsResponse({ payload: { id, data } }) {
    try {
        const response = yield call(addData, '/UpdatePaymentGatewayParam/'+id, data);
        if (response.Status) {
            yield put(actions.updatePaymentGatewayParamsSuccess(response.Message, response.Message));
            yield put(actions.getPaymentGateway());
        }
    } catch (error) {
        yield put(actions.addPaymentGatewayFailed(error));
    }
}

function* addPaymentGatewayResponse({ payload: { data } }) {
    try {
        const response = yield call(addData, '/AddPaymentGateway', data);
        if (response.Status) {
            yield put(actions.addPaymentGatewaySuccess(response.Message, response.Message));
            yield put(actions.getPaymentGateway());
        }
    } catch (error) {
        yield put(actions.addPaymentGatewayFailed(error));
    }
}

function* getPaymentGatewayResponse() {
    try {
        const response = yield call(getList, '/GetPaymentGateway');
        if (response.Status) {
            yield put(actions.getPaymentGatewaySuccess(response.PaymentGateway));
        }
    } catch (error) {
        yield put(actions.addPaymentGatewayFailed(error));
    }
}

function* updatePaymentGatewayShowOnCheckoutResponse({ payload: { id, flag } }) {
    try {
        const response = yield call(getList, '/UpdatePaymentGateShowOnCheckout/' + id + '/' + flag);
        if (response.Status) {
            yield put(actions.updatePaymentGatewayShowOnCheckoutSuccess(response.Message));
        }else{
            yield put(actions.updatePaymentGatewayShowOnCheckoutFailed(response.Message));
            // yield put(actions.getPaymentGateway());
        }
    } catch (error) {
        yield put(actions.updatePaymentGatewayShowOnCheckoutFailed(error));
    }
}

export default function* rootSaga() {
    yield all([takeEvery(actions.ADD_PAYMENT_GATEWAY, addPaymentGatewayResponse)]);
    yield all([takeEvery(actions.GET_PAYMENT_GATEWAY, getPaymentGatewayResponse)]);
    yield all([takeEvery(actions.UPDATE_PAYMENT_GATEWAY_PARAMS, updatePaymentGatewayParamsResponse)]);
    yield all([takeEvery(actions.UPDATE_PAYMENT_GATEWAY_SHOW_ON_CHECKOUT, updatePaymentGatewayShowOnCheckoutResponse)]);

}