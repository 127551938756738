import { all, takeEvery, put, call } from 'redux-saga/effects';
import actions from './actions';
import { addData } from '../../helpers/fackBackend_Helper';

function* getRefundDataListResponse({ payload: { data } }) {
    try {
        const response = yield call(addData, '/checkSimStatusReport', data);
        if (response.response.Status) {
            yield put(
                actions.getDataListToRefundSuccess(
                    response.response.Data
                )
            )
        } else {
            yield put(actions.getDataListToRefundFailed(response.response.Message));
        }
    } catch (error) {
        yield put(actions.getDataListToRefundFailed(error));
    }
}

function* toRefundRequestResponse({ payload: { refundData } }) {
    const dataIccid = {
        ICCID: refundData.ICCID
    }
    try {
        const response = yield call(addData, '/orderRefundRequest', refundData);
        if (response.response.Status) {
            const responseList = yield call(addData, '/checkSimStatusReport', dataIccid);
            if (responseList.response.Status) {
                yield put(
                    actions.toRefundRequestSuccess(
                        response.response.Message, responseList.response.Data
                    )
                )
            } else {
                yield put(actions.toRefundRequestFailed(response.response.Message));
            }
        } else {
            yield put(actions.toRefundRequestFailed(response.response.Message));
        }
    } catch (error) {
        yield put(actions.toRefundRequestFailed(error));
    }
}

export default function* rootSaga() {
    yield all([takeEvery(actions.GET_DATA_LIST_TO_REFUND, getRefundDataListResponse)]);
    yield all([takeEvery(actions.TO_REFUND_REQUEST, toRefundRequestResponse)]);
}