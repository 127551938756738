const actions = {
    GET_TRENDING_PLAN_REPORT: "GET_TRENDING_PLAN_REPORT",
    GET_TRENDING_PLAN_REPORT_SUCCESS: "GET_TRENDING_PLAN_REPORT_SUCCESS",
    GET_TRENDING_PLAN_REPORT_FAILED: "GET_TRENDING_PLAN_REPORT_FAILED",
    RESET_TRENDING_PLAN_REPORT_MESSAGE: "RESET_TRENDING_PLAN_REPORT_MESSAGE",
    getTrendingPlanReport: (Data) => ({
        type: actions.GET_TRENDING_PLAN_REPORT,
        payload: { Data },
    }),
    getTrendingPlanReportSuccess: (trendingResult) => ({
        type: actions.GET_TRENDING_PLAN_REPORT_SUCCESS,
        trendingResult
    }),
    getTrendingPlanReportFailed: (error) => ({
        type: actions.GET_TRENDING_PLAN_REPORT_FAILED,
        error
    }),
    resetTrendingPlanReportMessage: () => ({
        type: actions.RESET_TRENDING_PLAN_REPORT_MESSAGE
    })

}

export default actions;