const actions = {
    GET_PURCHASE: 'GET_PURCHASE',
    GET_PURCHASE_SUCCESS: 'GET_PURCHASE_SUCCESS',
    GET_PURCHASE_FAILED: 'GET_PURCHASE_FAILED',
    ADD_PURCHASE: "ADD_PURCHASE",
    ADD_PURCHASE_SUCCESS: "ADD_PURCHASE_SUCCESS",
    ADD_PURCHASE_FAILED: "ADD_PURCHASE_FAILED",
    GET_PURCHASE_DETAILS: "GET_PURCHASE_DETAILS",
    GET_PURCHASE_DETAILS_SUCCESS: "GET_PURCHASE_DETAILS_SUCCESS",
    PREPARE_ADD_PURCHASE_FORM: "PREPARE_ADD_PURCHASE_FORM",
    PREPARE_ADD_PURCHASE_FORM_SUCCESS: "PREPARE_ADD_PURCHASE_FORM_SUCCESS",
    PREPARE_ADD_PURCHASE_FORM_FAILED: "PREPARE_ADD_PURCHASE_FORM_FAILED",
    RESET_PURCHASE: "RESET_PURCHASE",
    UPLOAD_SIM_PURCHASE: "UPLOAD_SIM_PURCHASE",
    UPLOAD_SIM_PURCHASE_SUCCESS: "UPLOAD_SIM_PURCHASE_SUCCESS",
    UPLOAD_SIM_PURCHASE_FAILED: "UPLOAD_SIM_PURCHASE_FAILED",
    RESET_PIRCHASE_MESSGAE: "RESET_PIRCHASE_MESSGAE",
    OPEN_ADD_PURCHASE: "OPEN_ADD_PURCHASE",
    getPurchase: () => ({
        type: actions.GET_PURCHASE,
    }),
    getPurchaseSuccess: (purchaseResult) => ({
        type: actions.GET_PURCHASE_SUCCESS,
        purchaseResult
    }),
    getPurchaseFailed: (purchaseError) => ({
        type: actions.GET_PURCHASE_FAILED,
        purchaseError
    }),
    addPurchase: (data) => ({
        type: actions.ADD_PURCHASE,
        payload: { data }
    }),
    addPurchaseSuccess: (purchaseMessage, purchaseResult) => ({
        type: actions.ADD_PURCHASE_SUCCESS,
        purchaseMessage, purchaseResult
    }),
    addPurchaseFailed: (purchaseError) => ({
        type: actions.ADD_PURCHASE_FAILED,
        error: purchaseError
    }),
    getPurchaseDetails: (purchaseId) => ({
        type: actions.GET_PURCHASE_DETAILS,
        payload: { purchaseId }
    }),
    getPurchaseDetailsSuccess: (purchaseDetails) => ({
        type: actions.GET_PURCHASE_DETAILS_SUCCESS,
        purchaseDetails
    }),
    prepareAddPurchaseForm: () => ({
        type: actions.PREPARE_ADD_PURCHASE_FORM
    }),
    prepareAddPurchaseFormSuccess: (purInvResult) => ({
        type: actions.PREPARE_ADD_PURCHASE_FORM_SUCCESS,
        purInvResult
    }),
    prepareAddPurchaseFormFailed: (purchaseError) => ({
        type: actions.PREPARE_ADD_PURCHASE_FORM_FAILED,
        purchaseError
    }),
    resetPurchase: () => ({
        type: actions.RESET_PURCHASE
    }),
    uploadSimPurchase: (simDataInfo) => ({
        type: actions.UPLOAD_SIM_PURCHASE,
        payload: { simDataInfo }
    }),
    uploadSimPurchaseSuccess: (purchaseMessage) => ({
        type: actions.UPLOAD_SIM_PURCHASE_SUCCESS,
        purchaseMessage
    }),
    uploadSimPurchaseFailed: (purchaseError, simData) => ({
        type: actions.UPLOAD_SIM_PURCHASE_FAILED,
        purchaseError,
        simData
    }),
    resetPurchaseMessage: () => ({
        type: actions.RESET_PIRCHASE_MESSGAE
    }),
    open_add_purchase: () => ({
        type: actions.OPEN_ADD_PURCHASE
    })
}
export default actions;