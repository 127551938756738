import { all, takeEvery, put, call } from 'redux-saga/effects';
import actions from './actions';
import { addData, getList } from '../../helpers/fackBackend_Helper';

function* uploadEsimGoPlansResponse({ payload: { data } }) {
    try {
        const response = yield call(addData, '/uploadEsimGoPlan', data);
        if (response.Status) {
            yield put(actions.uploadEsimGoPlanSuccess(response.Message));
        }
    } catch (error) {
        yield put(actions.uploadEsimGoPlanFailed(error));
    }
}

function* getEsimGoPlansResponse() {
    try {
        const response = yield call(getList, '/getEsimGoPlan');
        if (response.Status) {
            yield put(actions.getEsimGoPlanSuccess(response.Plans));
        }
    } catch (error) {
        yield put(actions.getEsimGoPlanFailed(error));
    }
}

export default function* rootSaga() {
    yield all([takeEvery(actions.UPLOAD_ESIM_GO_PLAN, uploadEsimGoPlansResponse)]);
    yield all([takeEvery(actions.GET_ESIM_GO_PLAN, getEsimGoPlansResponse)]);

}