import { all, takeEvery, put, call } from 'redux-saga/effects';
import actions from './actions';
import { addData } from '../../helpers/fackBackend_Helper';


function* checkImeiResponse({ payload: { imei } }) {
    try {
        const response = yield call(addData, '/checkImei', imei);
        if (response.Status) {
            yield put(
                actions.checkImeiSuccess(response));
        } else {
            yield put(actions.checkImeiFailed(response.Message));
        }
    } catch (error) {
        yield put(actions.checkImeiFailed(error));
    }
}

export default function* rootSaga() {
    yield all([takeEvery(actions.CHECK_IMEI, checkImeiResponse)]);
}
