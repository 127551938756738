import actions from "./actions";

const initState = {
    purchaseResult: [],
    loading: false,
    simPurchaseLoading: false,
    purchaseMessage: null,
    purchaseError: null,
    buttonLoading: false,
    verifyResult: [],
    purInvResult: [],
    showAdd: false,
    showDetail: false,
    purchaseDetails: [],
    uploadMessage: null,
    uploadError: null,
    showingSubmitButton: false,
    duplicatedSimDataInv: []
};

export default function reducer(state = initState, action) {
    switch (action.type) {
        case actions.GET_PURCHASE:
            return {
                ...state,
                loading: true,
                simPurchaseLoading: true,
                purchaseError: null,
                purchaseMessage: null,
                buttonLoading: false,
            };
        case actions.GET_PURCHASE_SUCCESS:
            return {
                ...state,
                loading: false,
                simPurchaseLoading: false,
                purchaseError: null,
                purchaseResult: action.purchaseResult,
                // purInvResult: action.purInvResult,
                purchaseMessage: null,
                buttonLoading: false,
            };
        case actions.GET_PURCHASE_FAILED:
            return {
                ...state,
                loading: false,
                simPurchaseLoading: false,
                purchaseError: action.purchaseError,
                purchaseMessage: null,
                buttonLoading: false,
            };
        case actions.ADD_PURCHASE:
            return {
                ...state,
                loading: false,
                purchaseError: null,
                purchaseMessage: null,
                buttonLoading: true
            };
        case actions.ADD_PURCHASE_SUCCESS:
            return {
                ...state,
                loading: false,
                purchaseResult: action.purchaseResult,
                purchaseMessage: action.purchaseMessage,
                purchaseError: null,
                buttonLoading: false,
                showDetail: false,
                showAdd: false
            };
        case actions.ADD_PURCHASE_FAILED:
            return {
                ...state,
                loading: false,
                purchaseMessage: null,
                purchaseError: action.purchaseError,
                buttonLoading: false
            };
        case actions.GET_PURCHASE_DETAILS:
            return {
                ...state,
                loading: true,
                purchaseMessage: null,
                purchaseError: null,
                showDetail: true,
                showAdd: false
            };
        case actions.GET_PURCHASE_DETAILS_SUCCESS:
            return {
                ...state,
                loading: false,
                purchaseDetails: action.purchaseDetails,
                showDetail: true,
                showAdd: false
            };
        case actions.PREPARE_ADD_PURCHASE_FORM:
            return {
                ...state,
                loading: false,
                // showDetail: false,
                // showAdd: true,
                purchaseError: null,
                purchaseMessage: null
            };
        case actions.PREPARE_ADD_PURCHASE_FORM_SUCCESS:
            return {
                ...state,
                loading: false,
                purInvResult: action.purInvResult,
                // showDetail: false,
                // showAdd: true,
                purchaseError: null,
                purchaseMessage: null
            };
        case actions.PREPARE_ADD_PURCHASE_FORM_FAILED:
            return {
                ...state,
                loading: false,
                // showDetail: false,
                // showAdd: true,
                purchaseError: action.purchaseError,
                purchaseMessage: null
            };
        case actions.RESET_PURCHASE:
            return {
                ...state,
                loading: false,
                showDetail: false,
                showAdd: false,
                purchaseError: null,
                purchaseMessage: null
            };
        case actions.UPLOAD_SIM_PURCHASE:
            return {
                ...state,
                loading: false
            };
        case actions.UPLOAD_SIM_PURCHASE_SUCCESS:
            return {
                ...state,
                loading: false,
                purchaseMessage: action.purchaseMessage,
                showingSubmitButton: true
            };
        case actions.UPLOAD_SIM_PURCHASE_FAILED:
            return {
                ...state,
                loading: false,
                purchaseError: action.purchaseError,
                showingSubmitButton: false,
                duplicatedSimDataInv: action.simData.split(',')
            };
        case actions.RESET_PIRCHASE_MESSGAE:
            return {
                ...state,
                loading: false,
                purchaseError: null,
                purchaseMessage: null
            };
        case actions.OPEN_ADD_PURCHASE:
            return {
                ...state,
                loading: false,
                purchaseError: null,
                purchaseMessage: null,
                showDetail: false,
                showAdd: true,
            };

        default:
            return state;
    }
}