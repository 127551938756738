import actions from "./actions";

const initState = {
    orderListResult: [],
    loading: false,
    orderListError: null,
    orderListMessage: null,
    orderDetailsResult: [],
    planDetailsResult: [],
    orderViewModalData: [],
};

export default function reducer(state = initState, action) {
    switch (action.type) {
        case actions.OPEN_ORDER_MODAL:
            state.orderListResult.map((item) => {
                let temp = item;
                if (temp.ID == action.payload.orderID) {
                    temp.loading = !temp.loading;
                }
                return temp;
            });

            return {
                ...state,
            };
        case actions.OPEN_ORDER_MODAL_SUCCESS:
            state.orderListResult.map((item) => {
                let temp = item;
                if (temp.ID == action.orderID) {
                    temp.loading = !temp.loading;
                }
                return temp;
            });
            return {
                ...state,
                orderViewModal: true,
                orderViewModalData: action.data
            };

        case actions.CLOSE_ORDER_MODAL:
            return {
                ...state,
                orderViewModalData: [],
            };

        case actions.GET_ORDER_LIST:
            return {
                ...state,
                loading: true,
                orderListError: null,
                orderListMessage: null,
            };
        case actions.GET_ORDER_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                orderListError: null,
                orderListMessage: null,
                orderListResult: action.orderListResult,
            };
        case actions.GET_ORDER_LIST_FAILED:
            return {
                ...state,
                loading: false,
                orderListError: action.orderListError,
                orderListMessage: null,
            };
        case actions.GET_ORDER_DETAILS_LIST:
            return {
                ...state,
                loading: true,
                orderListError: null,
                orderListMessage: null,
            };
        case actions.GET_ORDER_DETAILS_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                orderListError: null,
                orderListMessage: null,
                orderDetailsResult: action.orderDetailsResult,
                planDetailsResult: action.orderDetailsResult,
            };
        case actions.GET_ORDER_DETAILS_LIST_FAILED:
            return {
                ...state,
                loading: false,
                orderListError: action.orderListError,
                orderListMessage: null,
            };
        default:
            return state;
    }
}