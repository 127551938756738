import actions from "./actions";

const initState = {
    cartResult: [],
    loading: true,
    cartError: null,
    cartMessage: null,
    paymentSuccessMessage: null,
    paymentErrorMessage: null,
    paymentProcessingMessage: null,
    proccessingData: [],
    showReceiptPage: false,

    applyCouponResult: [],
    applyCouponError: null,
    applyCouponMessage: null,
    applyCouponLoading: false,
    isCouponApplied: false,
};

export default function reducer(state = initState, action) {
    switch (action.type) {
        case actions.VIEW_CART_DATA:
            return {
                ...state,
                loading: true,
                applyCouponResult: [],
                isCouponApplied: false,
            };
        case actions.VIEW_CART_DATA_SUCCESSFULL:
            return {
                ...state,
                loading: false,
                cartError: null,
                cartResult: action.cartResult,
            };
        case actions.VIEW_CART_DATA_FAILED:
            return {
                ...state,
                loading: false,
                cartError: action.cartError,
            };
        case actions.UPDATE_CART_QUANTITY:
            return {
                ...state,
                loading: true,
                cartError: null,
            };
        case actions.UPDATE_CART_QUANTITY_SUCCESSFULL:
            return {
                ...state,
                loading: false,
                cartError: null,
                cartResult: action.cartResult,
                cartMessage: action.message
            };
        case actions.DELETE_CART:
            return {
                ...state,
                loading: true,
                cartError: null,
            };
        case actions.DELETE_CART_SUCCESSFULL:
            return {
                ...state,
                loading: false,
                cartError: null,
                cartResult: action.cartResult,
                cartMessage: action.message
            };
        case actions.RESET_CART_MESSAGE:
            return {
                ...state,
                loading: false,
                cartError: null,
                cartMessage: null,
                paymentSuccessMessage: null,
                paymentErrorMessage: null,
                paymentProcessingMessage: null,
                showReceiptPage: false,
            };
        case actions.PURCHASE_PLAN_PAYMENT:
            return {
                ...state,
                loading: true,
                cartError: null,
                cartMessage: null
            };
        case actions.PURCHASE_PLAN_PAYMENT_SUCCESS:
            return {
                ...state,
                loading: false,
                paymentSuccessMessage: action.message,
                cartError: null,
                isCouponApplied: false,
                applyCouponResult: [],
            };
        case actions.PURCHASE_PLAN_PAYMENT_FAILED:
            return {
                ...state,
                loading: false,
                paymentErrorMessage: action.error,
            };
        case actions.PURCHASE_PLAN_PAYMENT_PROCESSING:
            return {
                ...state,
                loading: false,
                paymentProcessingMessage: action.error,
                proccessingData: action.proccessingData
            };
        case actions.PLAN_FORM_OPEN:
            return {
                ...state,
                showReceiptPage: true
            };
        case actions.COUPON_APPLY:
            return {
                ...state,
                applyCouponError: null,
                applyCouponMessage: null,
                applyCouponLoading: true,
                isCouponApplied: false,

            };
        case actions.COUPON_APPLY_SUCCESS:
            return {
                ...state,
                applyCouponError: null,
                applyCouponResult: action.result,
                applyCouponMessage: action.message,
                applyCouponLoading: false,
                isCouponApplied: true,

            };
        case actions.COUPON_APPLY_FAILED:
            return {
                ...state,
                applyCouponError: action.error,
                applyCouponResult: [],
                applyCouponMessage: null,
                applyCouponLoading: false,
                isCouponApplied: false,
            };
        case actions.COUPON_FORM_RESET:
            return {
                ...state,
                applyCouponError: null,
                applyCouponResult: [],
                applyCouponMessage: null,
                applyCouponLoading: false,
                isCouponApplied: false,
            };
        case actions.RESET_COUPON:
            return {
                ...state,
                couponError: null,
                regionMessage: null,
                applyCouponError: null,
                applyCouponMessage: null,
            };
        default:
            return state;
    }
}
