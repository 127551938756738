import actions from "./actions";

const initState = {
    planListDataResult: [],
    loading: false,
    planListDataError: null,
    planListDataMessage: null,
};

export default function reducer(state = initState, action) {
    switch (action.type) {
        case actions.GET_PLAN_LIST_DATA:
            return {
                ...state,
                loading: true,
                planListDataError: null,
                planListDataMessage: null,
            };
        case actions.GET_PLAN_LIST_DATA_SUCCESS:
            return {
                ...state,
                loading: false,
                planListDataError: null,
                planListDataMessage: null,
                planListDataResult: action.planListDataResult,
            };
        case actions.GET_PLAN_LIST_DATA_FAILED:
            return {
                ...state,
                loading: false,
                planListDataError: action.planListDataError,
                planListDataMessage: null,
            };
        default:
            return state;
    }
}