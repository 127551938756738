import { all, takeEvery, put, call } from 'redux-saga/effects';
import actions from './actions';
import { getList } from '../../helpers/fackBackend_Helper';

function* getWidgetsDataResponse() {
    try {
        const response = yield call(getList, '/getWidgetData');
        if (response.response.Status) {
            yield put(
                actions.getWidgetsDataSuccess(
                    response.response.Data
                ));
        } else {
            yield put(actions.getWidgetsDataFailed(response.response.Message));
        }
    } catch (error) {
        yield put(actions.getWidgetsDataFailed(error));
    }
}

function* getSalesOverviewByRegionDataResponse() {
    try {
        const response = yield call(getList, '/getSalesOverviewByRegion');
        if (response.response.Status) {
            yield put(
                actions.getSalesOverviewByRegionSuccess(
                    response.response.Data
                ));
        } else {
            yield put(actions.getWidgetsDataFailed(response.response.Message));
        }
    } catch (error) {
        yield put(actions.getWidgetsDataFailed(error));
    }
}

function* getPlanOverviewByCountryDataResponse() {
    try {
        const response = yield call(getList, '/getPlanOverviewByCountry');
        if (response.response.Status) {
            yield put(
                actions.getPlanOverviewByCountrySuccess(
                    response.response.Data
                ));
        } else {
            yield put(actions.getWidgetsDataFailed(response.response.Message));
        }
    } catch (error) {
        yield put(actions.getWidgetsDataFailed(error));
    }
}

function* getInventoryLowStockDataResponse() {
    try {
        const response = yield call(getList, '/getInventoryLowStock');
        if (response.response.Status) {
            yield put(
                actions.getInventoryLowStockReportSuccess(
                    response.response.Data
                ));
        } else {
            yield put(actions.getInventoryLowStockReportFailed(response.response.Message));
        }
    } catch (error) {
        yield put(actions.getInventoryLowStockReportFailed(error));
    }
}

export default function* rootSaga() {
    yield all([takeEvery(actions.GET_WIDGETS_DATA, getWidgetsDataResponse)]);
    yield all([takeEvery(actions.GET_SALES_OVERVIEW_BY_REGION, getSalesOverviewByRegionDataResponse)]);
    yield all([takeEvery(actions.GET_PLAN_OVERVIEW_BY_COUNTRY, getPlanOverviewByCountryDataResponse)]);
    yield all([takeEvery(actions.GET_INVENTORY_LOW_STOCK_REPORT, getInventoryLowStockDataResponse)]);
}
