const actions = {
    OPEN_ADD_AGGREGATOR_MODAL: "OPEN_ADD_AGGREGATOR_MODAL",
    CLOSE_ADD_AGGREGATOR_MODAL: "CLOSE_ADD_AGGREGATOR_MODAL",
    ADD_AGGREGATOR: "ADD_AGGREGATOR",
    ADD_AGGREGATOR_SUCCESS: "ADD_AGGREGATOR_SUCCESS",
    ADD_AGGREGATOR_FAILED: "ADD_AGGREGATOR_FAILED",
    GET_AGGREGATOR: "GET_AGGREGATOR",
    GET_AGGREGATOR_SUCCESS: "GET_AGGREGATOR_SUCCESS",
    GET_AGGREGATOR_FAILED: "GET_AGGREGATOR_FAILED",
    OPEN_EDIT_PARAM_AGGREGATOR_MODAL: "OPEN_EDIT_PARAM_AGGREGATOR_MODAL",
    CLOSE_EDIT_PARAM_AGGREGATOR_MODAL: "CLOSE_EDIT_PARAM_AGGREGATOR_MODAL",
    UPDATE_AGGREGATOR_PARAMS: "UPDATE_AGGREGATOR_PARAMS",
    UPDATE_AGGREGATOR_PARAMS_SUCCESS: "UPDATE_AGGREGATOR_PARAMS_SUCCESS",
    UPDATE_AGGREGATOR_PARAMS_FAILED: "UPDATE_AGGREGATOR_PARAMS_FAILED",   
    SHOW_PARAMS_PREVIEW: "SHOW_PARAMS_PREVIEW",
    HIDE_PARAMS_PREVIEW: "HIDE_PARAMS_PREVIEW",
    SET_PREVIEW_DATA: "SET_PREVIEW_DATA",
    SET_PREVIEW_EDIT: "SET_PREVIEW_EDIT",
    
    GET_AGGREGATOR_DATA:"GET_AGGREGATOR_DATA",

    GET_AGGREGATOR_ACTIVE: "GET_AGGREGATOR_ACTIVE",
    GET_AGGREGATOR_ACTIVE_SUCCESS: "GET_AGGREGATOR_ACTIVE_SUCCESS",
    GET_AGGREGATOR_ACTIVE_FAILED: "GET_AGGREGATOR_ACTIVE_FAILED",

    getAggregatorData: (data) => ({
        type: actions.GET_AGGREGATOR_DATA,
        payload: { data }
    }),


    setPreviewEdit: (id) => ({
        type: actions.SET_PREVIEW_EDIT,
        payload: { id }
    }),
    setPreviewData: (data) => ({
        type: actions.SET_PREVIEW_DATA,
        payload: { data }
    }),
    hideParamsPreview: () => ({
        type: actions.HIDE_PARAMS_PREVIEW,
    }),
    showParamsPreview: () => ({
        type: actions.SHOW_PARAMS_PREVIEW,
    }),

    updateAggregatorParams: (id,data) => ({
        type: actions.UPDATE_AGGREGATOR_PARAMS,
        payload: {id, data }
    }),
    updateAggregatorParamsSuccess: (regionMessage, regionResult) => ({
        type: actions.UPDATE_AGGREGATOR_PARAMS_SUCCESS,
        regionMessage, regionResult
    }),
    updateAggregatorParamsFailed: (error) => ({
        type: actions.UPDATE_AGGREGATOR_PARAMS_FAILED,
        error
    }),

    openEditParamAggregatorModal: () => ({
        type: actions.OPEN_EDIT_PARAM_AGGREGATOR_MODAL,
    }),
    closeEditParamAggregatorModal: () => ({
        type: actions.CLOSE_EDIT_PARAM_AGGREGATOR_MODAL,
    }),
    getAggregator: () => ({
        type: actions.GET_AGGREGATOR,
    }),
    getAggregatorSuccess: (data) => ({
        type: actions.GET_AGGREGATOR_SUCCESS,
        data
    }),
    getAggregatorFailed: (error) => ({
        type: actions.GET_AGGREGATOR_FAILED,
        error
    }),
    openAddAggregatorModal: () => ({
        type: actions.OPEN_ADD_AGGREGATOR_MODAL,
    }),
    closeAddAggregatorModal: () => ({
        type: actions.CLOSE_ADD_AGGREGATOR_MODAL,
    }),
    addAggregator: (data) => ({
        type: actions.ADD_AGGREGATOR,
        payload: { data }
    }),
    addAggregatorSuccess: (regionMessage, regionResult) => ({
        type: actions.ADD_AGGREGATOR_SUCCESS,
        regionMessage, regionResult
    }),
    addAggregatorFailed: (error) => ({
        type: actions.ADD_AGGREGATOR_FAILED,
        error
    }),
    getActiveAggregator: () => ({
        type: actions.GET_AGGREGATOR_ACTIVE,
    }),
    getActiveAggregatorSuccess: (data) => ({
        type: actions.GET_AGGREGATOR_ACTIVE_SUCCESS,
        data
    }),
    getActiveAggregatorFailed: (error) => ({
        type: actions.GET_AGGREGATOR_ACTIVE_FAILED,
        error
    }),
}
export default actions;