const actions = {
    RESET_MESSAGES: "RESET_MESSAGES",
    OPEN_ADD_CATEGORY_MODAL: "OPEN_ADD_CATEGORY_MODAL",
    CLOSE_ADD_CATEGORY_MODAL: "CLOSE_ADD_CATEGORY_MODAL",
    ADD_CATEGORY: "ADD_CATEGORY",
    ADD_CATEGORY_SUCCESS: "ADD_CATEGORY_SUCCESS",
    ADD_CATEGORY_FAILED: "ADD_CATEGORY_FAILED",
    OPEN_ADD_TEMPLATE_MODAL: "OPEN_ADD_TEMPLATE_MODAL",
    CLOSE_ADD_TEMPLATE_MODAL: "CLOSE_ADD_TEMPLATE_MODAL",
    GET_TEMPLATE_CATEGORY: "GET_TEMPLATE_CATEGORY",
    GET_TEMPLATE_CATEGORY_SUCCESS: "GET_TEMPLATE_CATEGORY_SUCCESS",
    GET_TEMPLATE_CATEGORY_FAILED: "GET_TEMPLATE_CATEGORY_FAILED",
    ADD_EMAIL_TEMPLATE: "ADD_EMAIL_TEMPLATE",
    ADD_EMAIL_TEMPLATE_SUCCESS: "ADD_EMAIL_TEMPLATE_SUCCESS",
    ADD_EMAIL_TEMPLATE_FAILED: "ADD_EMAIL_TEMPLATE_FAILED",
    GET_TEMPLATE: "GET_TEMPLATE",
    GET_TEMPLATE_SUCCESS: "GET_TEMPLATE_SUCCESS",
    GET_TEMPLATE_FAILED: "GET_TEMPLATE_FAILED",
    UPDATE_TEMPLATE_STATUS: "UPDATE_TEMPLATE_STATUS",
    UPDATE_TEMPLATE_STATUS_SUCCESS: "UPDATE_TEMPLATE_STATUS_SUCCESS",
    UPDATE_TEMPLATE_STATUS_FAILED: "UPDATE_TEMPLATE_STATUS_FAILED",
    UPDATE_TEMPLATE: "UPDATE_TEMPLATE",
    UPDATE_TEMPLATE_SUCCESS: "UPDATE_TEMPLATE_SUCCESS",
    UPDATE_TEMPLATE_FAILED: "UPDATE_TEMPLATE_FAILED",
    UPDATE_CATEGORY_STATUS: "UPDATE_CATEGORY_STATUS",
    UPDATE_CATEGORY_STATUS_SUCCESS: "UPDATE_CATEGORY_STATUS_SUCCESS",
    UPDATE_CATEGORY_STATUS_FAILED: "UPDATE_CATEGORY_STATUS_FAILED",
    UPDATE_TEMPLATE_CATEGORY: "UPDATE_TEMPLATE_CATEGORY",
    UPDATE_TEMPLATE_CATEGORY_SUCCESS: "UPDATE_TEMPLATE_CATEGORY_SUCCESS",
    UPDATE_TEMPLATE_CATEGORY_FAILED: "UPDATE_TEMPLATE_CATEGORY_FAILED",

    updateTemplateCategory: (id, data) => ({
        type: actions.UPDATE_TEMPLATE_CATEGORY,
        payload: { id, data }
    }),
    updateTemplateCategorySuccess: (message) => ({
        type: actions.UPDATE_TEMPLATE_CATEGORY_SUCCESS,
        payload: { message }
    }),
    updateTemplateCategoryFailed: (error) => ({
        type: actions.UPDATE_TEMPLATE_CATEGORY_FAILED,
        payload: { error }
    }),

    updateCategoryStatus: (id, flag) => ({
        type: actions.UPDATE_CATEGORY_STATUS,
        payload: { id, flag }
    }),
    updateCategoryStatusSuccess: (message) => ({
        type: actions.UPDATE_CATEGORY_STATUS_SUCCESS,
        payload: { message }
    }),
    updateCategoryStatusFailed: (error) => ({
        type: actions.UPDATE_CATEGORY_STATUS_FAILED,
        payload: { error }
    }),

    updateTemplate: (id, data) => ({
        type: actions.UPDATE_TEMPLATE,
        payload: { id, data }
    }),
    updateTemplateSuccess: (message) => ({
        type: actions.UPDATE_TEMPLATE_SUCCESS,
        payload: { message }
    }),
    updateTemplateFailed: (error) => ({
        type: actions.UPDATE_TEMPLATE_FAILED,
        payload: { error }
    }),

    updateTemplateStatus: (id, flag) => ({
        type: actions.UPDATE_TEMPLATE_STATUS,
        payload: { id, flag }
    }),
    updateTemplateStatusSuccess: (message) => ({
        type: actions.UPDATE_TEMPLATE_STATUS_SUCCESS,
        payload: { message }
    }),
    updateTemplateStatusFailed: (error) => ({
        type: actions.UPDATE_TEMPLATE_STATUS_FAILED,
        payload: { error }
    }),

    getTemplate: () => ({
        type: actions.GET_TEMPLATE
    }),
    getTemplateSuccess: (data) => ({
        type: actions.GET_TEMPLATE_SUCCESS,
        payload: { data }
    }),
    getTemplateFailed: (error) => ({
        type: actions.GET_TEMPLATE_FAILED,
        payload: { error }
    }),

    addEmailTemplate: (data) => ({
        type: actions.ADD_EMAIL_TEMPLATE,
        payload: { data }
    }),
    addEmailTemplateSuccess: (message) => ({
        type: actions.ADD_EMAIL_TEMPLATE_SUCCESS,
        payload: { message }
    }),
    addEmailTemplateFailed: (error) => ({
        type: actions.ADD_EMAIL_TEMPLATE_FAILED,
        payload: { error }
    }),

    getTemplateCategory: () => ({
        type: actions.GET_TEMPLATE_CATEGORY
    }),
    getTemplateCategorySuccess: (data) => ({
        type: actions.GET_TEMPLATE_CATEGORY_SUCCESS,
        payload: { data }
    }),
    getTemplateCategoryFailed: (error) => ({
        type: actions.GET_TEMPLATE_CATEGORY_FAILED,
        payload: { error }
    }),

    addCategory: (data) => ({
        type: actions.ADD_CATEGORY,
        payload: { data }
    }),
    addCategorySuccess: (message) => ({
        type: actions.ADD_CATEGORY_SUCCESS,
        payload: { message }
    }),
    addCategoryFailed: (error) => ({
        type: actions.ADD_CATEGORY_FAILED,
        payload: { error }
    }),

    openAddTemplateModal: () => ({
        type: actions.OPEN_ADD_TEMPLATE_MODAL,
    }),
    closeAddTemplateModal: () => ({
        type: actions.CLOSE_ADD_TEMPLATE_MODAL,
    }),

    openAddCategoryModal: () => ({
        type: actions.OPEN_ADD_CATEGORY_MODAL,
    }),
    closeAddCategoryModal: () => ({
        type: actions.CLOSE_ADD_CATEGORY_MODAL,
    }),

    resetMessages: () => ({
        type: actions.RESET_MESSAGES,
    }),
}
export default actions;