const actions = {
    AMOUNT_DATA: 'AMOUNT_DATA',
    AMOUNT_DATA_SUCCESS: 'AMOUNT_DATA_SUCCESS',
    AMOUNT_DATA_FAILED: 'AMOUNT_DATA_FAILED',
    CREATE_PAYMENT_ID: "CREATE_PAYMENT_ID",
    CREATE_PAYMENT_ID_SUCCESS: "CREATE_PAYMENT_ID_SUCCESS",
    CREATE_PAYMENT_ID_FAILED: "CREATE_PAYMENT_ID_FAILED",
    SEND_PAYMENT_DATA: "SEND_PAYMENT_DATA",
    SEND_PAYMENT_DATA_SUCCESS: "SEND_PAYMENT_DATA_SUCCESS",
    SEND_PAYMENT_DATA_FAILED: "SEND_PAYMENT_DATA_FAILED",
    GET_TRANSACTION_HISTORY: "GET_TRANSACTION_HISTORY",
    GET_TRANSACTION_HISTORY_SUCCESS: "GET_TRANSACTION_HISTORY_SUCCESS",
    GET_TRANSACTION_HISTORY_FAILED: "GET_TRANSACTION_HISTORY_FAILED",
    RESET_ADD_TO_FUND_MESSAGE: "RESET_ADD_TO_FUND_MESSAGE",
    GET_PAYMENT_GATEWAY_DEALER: "GET_PAYMENT_GATEWAY_DEALER",
    GET_PAYMENT_GATEWAY_DEALER_SUCCESS: "GET_PAYMENT_GATEWAY_DEALER_SUCCESS",
    GET_PAYMENT_GATEWAY_DEALER_FAILED: "GET_PAYMENT_GATEWAY_DEALER_FAILED",

    amountData: () => ({
        type: actions.AMOUNT_DATA,
    }),
    amountDataSuccess: (data) => ({
        type: actions.AMOUNT_DATA_SUCCESS,
        data
    }),
    amountDataFailed: (error) => ({
        type: actions.AMOUNT_DATA_FAILED,
        error
    }),
    createPaymentId: (data) => ({
        type: actions.CREATE_PAYMENT_ID,
        payload: { data }
    }),
    createPaymentIdSuccess: (PaymentId) => ({
        type: actions.CREATE_PAYMENT_ID_SUCCESS,
        PaymentId,
    }),
    createPaymentIdFailed: (Error) => ({
        type: actions.CREATE_PAYMENT_ID_FAILED,
        Error
    }),
    sendPaymentData: (data) => ({
        type: actions.SEND_PAYMENT_DATA,
        payload: { data }
    }),
    sendPaymentDataSuccess: (Message, data, trasactionResult) => ({
        type: actions.SEND_PAYMENT_DATA_SUCCESS,
        Message,
        data,
        trasactionResult
    }),
    sendPaymentDataFailed: (Error, trasactionResult) => ({
        type: actions.SEND_PAYMENT_DATA_FAILED,
        Error,
        trasactionResult
    }),
    getTransactionHistory: () => ({
        type: actions.GET_TRANSACTION_HISTORY,

    }),
    getTransactionHistorySuccess: (trasactionResult) => ({
        type: actions.GET_TRANSACTION_HISTORY_SUCCESS,
        trasactionResult
    }),
    getTransactionHistoryFailed: (error) => ({
        type: actions.GET_TRANSACTION_HISTORY_FAILED,
        error
    }),
    resetAddToFundMessage: () => ({
        type: actions.RESET_ADD_TO_FUND_MESSAGE,
    }),
    getPaymentGatewayDealer: () => ({
        type: actions.GET_PAYMENT_GATEWAY_DEALER,
    }),
    getPaymentGatewayDealerSuccess: (paymentGateway) => ({
        type: actions.GET_PAYMENT_GATEWAY_DEALER_SUCCESS,
        paymentGateway,
    }),
    getPaymentGatewayDealerFailed: (error) => ({
        type: actions.GET_PAYMENT_GATEWAY_DEALER_FAILED,
        error
    }),
};
export default actions;
