import { all, takeEvery, put, call } from 'redux-saga/effects';
import actions from './actions';
import { addData, getList } from '../../helpers/fackBackend_Helper';

function* getInventoryListResponse() {
    try {
        const response = yield call(getList, '/inventory');
        if (response.response.Status) {
            yield put(
                actions.getInventorySuccess(
                    response.response.Data
                ));
        } else {
            yield put(actions.getInventoryFailed(response.response.Message));
        }
    } catch (error) {
        yield put(actions.getInventoryFailed(error));
    }
}

function* toCheckDataUsageResponse({ payload: { data } }) {
    
    try {
        const response = yield call(addData, '/checkDataUsage', data);
        if (response.response.Status) {
            yield put(
                actions.toCheckDataUsageSuccess(
                    response.response.Data
                ));
        } else {
            yield put(actions.toCheckDataUsageFailed(response.response.Message));
        }
    } catch (error) {
        yield put(actions.toCheckDataUsageFailed(error));
    }
}

export default function* rootSaga() {
    yield all([takeEvery(actions.GET_INVENTORY, getInventoryListResponse)]);
    yield all([takeEvery(actions.TO_CHECK_DATA_USAGE, toCheckDataUsageResponse)]);
}
