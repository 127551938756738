import actions from "./actions";

const initState = {
    shortCodeResult: [],
    loading: false,
    shortCodeError: null,
};

export default function reducer(state = initState, action) {
    switch (action.type) {
        case actions.GET_SHORT_CODE_DATA:
            return {
                ...state,
                loading: true,
                shortCodeError: null,
            };
        case actions.GET_SHORT_CODE_DATA_SUCCESS:
            return {
                ...state,
                loading: false,
                shortCodeError: null,
                shortCodeResult: action.shortCodeResult,
            };
        case actions.GET_SHORT_CODE_DATA_FAILED:
            return {
                ...state,
                loading: false,
                shortCodeError: action.shortCodeError,
            };
        default:
            return state;
    }
}