const actions = {
    GET_TARIFF: 'GET_TARIFF',
    GET_TARIFF_SUCCESS: 'GET_TARIFF_SUCCESS',
    GET_TARIFF_FAILED: 'GET_TARIFF_FAILED',
    GET_TARIFF_DATA_BY_ID: "GET_TARIFF_DATA_BY_ID",
    GET_TARIFF_DATA_BY_ID_SUCCESS: "GET_TARIFF_DATA_BY_ID_SUCCESS",
    ADD_TARIFF: "ADD_TARIFF",
    ADD_TARIFF_SUCCESS: "ADD_TARIFF_SUCCESS",
    ADD_TARIFF_FAILED: "ADD_TARIFF_FAILED",
    EDIT_TARIFF: "EDIT_TARIFF",
    EDIT_TARIFF_SUCCESS: "EDIT_TARIFF_SUCCESS",
    DELETE_TARIFF: "DELETE_TARIFF",
    DELETE_TARIFF_SUCCESS: "DELETE_TARIFF_SUCCESS",
    RESET_TARIFF: "RESET_TARIFF",
    PREPARE_TARIFF_FORM: "PREPARE_TARIFF_FORM",
    RESER_TARIFF_MESSAGE:"RESER_TARIFF_MESSAGE",
    getTariff: () => ({
        type: actions.GET_TARIFF,
    }),
    getTariffSuccess: (tariffResult) => ({
        type: actions.GET_TARIFF_SUCCESS,
        tariffResult
    }),
    getTariffFailed: (tariffError) => ({
        type: actions.GET_TARIFF_FAILED,
        tariffError
    }),
    getTariffDataById: (tariffId) => ({
        type: actions.GET_TARIFF_DATA_BY_ID,
        payload: { tariffId }
    }),
    getTariffDataByIdSuccessfull: (tariffMapedData) => ({
        type: actions.GET_TARIFF_DATA_BY_ID_SUCCESS,
        tariffMapedData
    }),
    addTariff: (data) => ({
        type: actions.ADD_TARIFF,
        payload: { data }
    }),
    addTariffSuccess: (tariffMessage, tariffResult) => ({
        type: actions.ADD_TARIFF_SUCCESS,
        tariffResult,
        tariffMessage
    }),
    addTariffFailed: (tariffError) => ({
        type: actions.ADD_TARIFF_FAILED,
        tariffError
    }),
    editTariff: (tariffId, data) => ({
        type: actions.EDIT_TARIFF,
        payload: { tariffId, data }
    }),
    editTariffSuccess: (tariffMessage, tariffResult) => ({
        type: actions.EDIT_TARIFF_SUCCESS,
        tariffResult,
        tariffMessage
    }),
    deleteTariff: (tariffId, status) => ({
        type: actions.DELETE_TARIFF,
        payload: { tariffId, status }
    }),
    deleteTariffSuccess: (tariffMessage, tariffResult) => ({
        type: actions.DELETE_TARIFF_SUCCESS,
        tariffResult,
        tariffMessage
    }),
    prepareTariffForm: () => ({
        type: actions.PREPARE_TARIFF_FORM,
    }),
    resetTariff: () => ({
        type: actions.RESET_TARIFF,
    }),
    resetTariffMessage: () => ({
        type: actions.RESER_TARIFF_MESSAGE,
    })
};
export default actions;
