import actions from './actions';

const initState = { idToken: null, loginError: null, loginMessage: null, loading: false, tokenData: [], tokenError: null, buttonLoading: false };
export default function authReducer(state = initState, action) {
  switch (action.type) {
    case actions.LOGIN_REQUEST:
      return {
        ...state,
        loginError: null,
        loginMessage: null,
        loading: true
      };
    case actions.LOGIN_SUCCESS:
      return {
        idToken: action.token,
        loginError: null,
        loginMessage: null,
        loading: false
      };
    case actions.LOGIN_ERROR:
      return {
        loginMessage: null,
        loading: false,
        loginError: action.loginError,
      };
    case actions.LOGOUT:
      return initState;
    case actions.RESET_LOGIN_ERROR:
      return {
        ...state,
        loading: false,
        loginError: null,
        loginMessage: null,
        passwordFormModal: false,
        buttonLoading: false
      };
    case actions.CHANGE_PASSWORD:
      return {
        ...state,
        loading: false,
        loginError: null,
        loginMessage: null,
        buttonLoading: true,
      };
    case actions.CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
        loginMessage: action.loginMessage,
        loginError: null,
        buttonLoading: false,
        passwordFormModal: false
      };
    case actions.CHANGE_PASSWORD_FAILED:
      return {
        ...state,
        loading: false,
        loginMessage: null,
        loginError: action.loginError,
        buttonLoading: false,
        passwordFormModal: true
      };
    case actions.GET_USER_DATA_BY_TOKEN:
      return {
        ...state,
        loading: false,
        loginMessage: null,
        loginError: null
      };
    case actions.GET_USER_DATA_BY_TOKEN_SUCCESS:
      return {
        ...state,
        loading: false,
        loginMessage: null,
        loginError: null,
        tokenData: action.tokenData
      };
    case actions.GET_USER_DATA_BY_TOKEN_FAILED:
      return {
        ...state,
        loading: false,
        loginMessage: null,
        loginError: null,
        tokenError: action.tokenError
      };
    case actions.PREPARE_CHANGE_PASSWORD_FORM:
      return {
        ...state,
        loading: false,
        loginMessage: null,
        loginError: null,
        passwordFormModal: true
      };
    case actions.RESET_LOGIN_MESSAGE:
      return {
        ...state,
        loading: false,
        loginError: null,
        loginMessage: null,
        buttonLoading: false
      };
    case actions.VERIFY_EMAIL_ON_FORGET_PASSOWRD:
      return {
        ...state,
        loginError: null,
        loading: true,
        loginMessage: null,
      };
    case actions.VERIFY_EMAIL_ON_FORGET_PASSOWRD_SUCCESS:
      return {
        ...state,
        loginError: null,
        loading: false,
        loginMessage: action.loginMessage,
      };
    case actions.VERIFY_EMAIL_ON_FORGET_PASSWORD_FAILED:
      return {
        ...state,
        loginError: action.loginError,
        loading: false,
        loginMessage: null,
      };
    case actions.RESET_FORGET_PASSWORD:
      return {
        ...state,
        loginError: null,
        loading: true,
        loginMessage: null,
      };
    case actions.RESET_FORGET_PASSWORD_SUCCESS:
      return {
        ...state,
        loginError: null,
        loading: false,
        loginMessage: action.loginMessage,
      };
    case actions.RESET_FORGET_PASSWORD_FAILED:
      return {
        ...state,
        loginError: action.loginError,
        loading: false,
        loginMessage: null,
      };
    default:
      return state;
  }
}
