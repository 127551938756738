import actions from "./actions";

const initState = {
    loading: false,
    Message: null,
    manualAddFundMessage: null,
    manualAddFundError: null,
    afterPaymentSuccessMessage: null,
    updateManualTopUpFlag:false,
    Error: null,
};

export default function reducer(state = initState, action) {
    switch (action.type) {
        case actions.MANUAL_ADD_FUND:
            return {
                ...state,
                loading: true,
            };
        case actions.MANUAL_ADD_FUND_SUCCESS:
            return {
                ...state,
                loading: false,
                manualAddFundMessage: action.manualAddFundMessage,
                manualAddFundError:null,
                updateManualTopUpFlag:true
            };
        case actions.MANUAL_ADD_FUND_FAILED:
            return {
                ...state,
                manualAddFundMessage: null,
                manualAddFundError: action.manualAddFundError,
                updateManualTopUpFlag:true
            };
        case actions.RESET_MANUAL_ADD_FUND:
            return {
                ...state,
                manualAddFundMessage: null,
                manualAddFundError: null,
            };
        default:
            return state;
    }
}
