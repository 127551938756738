const actions = {
    VIEW_CART_DATA: 'VIEW_CART_DATA',
    VIEW_CART_DATA_SUCCESSFULL: 'VIEW_CART_DATA_SUCCESSFULL',
    VIEW_CART_DATA_FAILED: 'VIEW_CART_DATA_FAILED',
    UPDATE_CART_QUANTITY: "UPDATE_CART_QUANTITY",
    UPDATE_CART_QUANTITY_SUCCESSFULL: "UPDATE_CART_QUANTITY_SUCCESSFULL",
    DELETE_CART: "DELETE_CART",
    DELETE_CART_SUCCESSFULL: "DELETE_CART_SUCCESSFULL",
    RESET_CART_MESSAGE: "RESET_CART_MESSAGE",
    PURCHASE_PLAN_PAYMENT: "PURCHASE_PLAN_PAYMENT",
    PURCHASE_PLAN_PAYMENT_SUCCESS: "PURCHASE_PLAN_PAYMENT_SUCCESS",
    PURCHASE_PLAN_PAYMENT_FAILED: "PURCHASE_PLAN_PAYMENT_FAILED",
    PURCHASE_PLAN_PAYMENT_PROCESSING: "PURCHASE_PLAN_PAYMENT_PROCESSING",
    PLAN_FORM_OPEN: "PLAN_FORM_OPEN",
    COUPON_APPLY: "COUPON_APPLY",
    COUPON_APPLY_SUCCESS: "COUPON_APPLY_SUCCESS",
    COUPON_APPLY_FAILED: "COUPON_APPLY_FAILED",
    COUPON_FORM_RESET:"COUPON_FORM_RESET",
    RESET_COUPON: "RESET_COUPON",

    viewCartData: () => ({
        type: actions.VIEW_CART_DATA,
    }),
    viewCartDataSuccessfull: (cartResult) => ({
        type: actions.VIEW_CART_DATA_SUCCESSFULL,
        cartResult
    }),
    viewCartDataFailed: (cartError) => ({
        type: actions.VIEW_CART_DATA_FAILED,
        cartError
    }),
    updateCartQuantity: (data) => ({
        type: actions.UPDATE_CART_QUANTITY,
        payload: { data }
    }),
    updateCartQuantitySuccessfull: (message, cartResult) => ({
        type: actions.UPDATE_CART_QUANTITY_SUCCESSFULL,
        message, cartResult
    }),
    deleteCart: (cartId) => ({
        type: actions.DELETE_CART,
        payload: { cartId }
    }),
    deleteCartSuccessfull: (message, cartResult) => ({
        type: actions.DELETE_CART_SUCCESSFULL,
        message, cartResult
    }),
    resetCartMessage: () => ({
        type: actions.RESET_CART_MESSAGE
    }),
    purchasePlanPayment: (provideCustomerEmail) => ({
        type: actions.PURCHASE_PLAN_PAYMENT,
        payload: { provideCustomerEmail }
    }),
    purchasePlanPaymentSuccess: (message) => ({
        type: actions.PURCHASE_PLAN_PAYMENT_SUCCESS,
        message
    }),
    purchasePlanPaymentFailed: (error) => ({
        type: actions.PURCHASE_PLAN_PAYMENT_FAILED,
        error
    }),
    purchasePlanPaymentProcessing: (message, proccessingData) => ({
        type: actions.PURCHASE_PLAN_PAYMENT_PROCESSING,
        message, proccessingData
    }),
    planFormOpen: () => ({
        type: actions.PLAN_FORM_OPEN,
    }),
    applyCoupon: (data) => ({
        type: actions.COUPON_APPLY,
        payload: { data }
    }),
    applyCouponSuccess: (message, result) => ({
        type: actions.COUPON_APPLY_SUCCESS,
        message, result
    }),
    applyCouponFailed: (error) => ({
        type: actions.COUPON_APPLY_FAILED,
        error
    }),
    resetFormCoupon: () => ({
        type: actions.COUPON_FORM_RESET,
    }),
    resetCoupon: () => ({
        type: actions.RESET_COUPON
    }),
};
export default actions;
