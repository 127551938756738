import actions from "./actions";

const initState = {
    regionResult: [],
    loading: false,
    regionError: null,
    regionMessage: null,
    buttonLoading: false,
    regionFormModal: false,
    regionCountryData: [],
    // coupon part starts from here
    couponFormModal: false,
    couponData: [],
    couponLoading: false,
    couponError: null,
    couponMessage: null,
    tableLoading: false,

    // applyCouponResult: [],
    // applyCouponError: null,
    // applyCouponMessage: null,
    // applyCouponLoading: false,
    // isCouponApplied:false,
};

export default function reducer(state = initState, action) {
    switch (action.type) {
        case actions.PREPARE_COUPON_FORM:
            return {
                ...state,
                couponFormModal: true,
                couponError: null,
                couponMessage: null,
            };

        case actions.RESET_COUPON_FORM:
            return {
                ...state,
                couponFormModal: false
            };

        case actions.ADD_COUPON:
            return {
                ...state,
                couponLoading: true,
                couponError: null,
                couponMessage: null,
            };

        case actions.ADD_COUPON_SUCCESS:
            return {
                ...state,
                couponLoading: false,
                couponError: null,
                couponMessage: action.regionMessage,
                couponFormModal: false,
            };

        case actions.ADD_COUPON_FAILED:
            return {
                ...state,
                couponLoading: false,
                couponError: action.error,
                couponMessage: null,
                // couponFormModal : false,
            };

        case actions.GET_COUPON:
            return {
                ...state,
                tableLoading: true,
                couponError: null,
                couponMessage: null,
                couponFormModal: false
            };
        case actions.GET_COUPON_SUCCESS:
            return {
                ...state,
                tableLoading: false,
                couponError: null,
                couponMessage: null,
                couponFormModal: false,
                couponData: action.data
            };
        case actions.GET_COUPON_FAILED:
            return {
                ...state,
                tableLoading: false,
                couponError: action,
                couponMessage: null,
                couponFormModal: false
            };
        case actions.RESET_COUPON:
            return {
                ...state,
                couponError: null,
                regionMessage: null,
                applyCouponError:null,
                applyCouponMessage:null,
            };
        case actions.DELETE_COUPON:
            return {
                ...state,
                loading: false,
                regionError: null,
                regionMessage: null,
                buttonLoading: false,
                regionFormModal: false
            };
        case actions.DELETE_COUPON_SUCCESS:
            return {
                ...state,
                loading: false,
                regionMessage: action.regionMessage,
                regionError: null,
                buttonLoading: false,
                regionFormModal: false
            };
        case actions.EDIT_COUPON:
            return {
                ...state,
                couponLoading: true,
                couponError: null,
                couponMessage: null,
            };
        case actions.EDIT_COUPON_SUCCESS:
            return {
                ...state,
                couponLoading: false,
                couponError: null,
                couponMessage: action.regionMessage,
                couponFormModal: false,
            };

        // case actions.COUPON_APPLY:
        //     return {
        //         ...state,
        //         applyCouponError: null,
        //         applyCouponMessage: null,
        //         applyCouponLoading: true,
        //         isCouponApplied:false,

        //     };
        //     case actions.COUPON_APPLY_SUCCESS:
        //     return {
        //         ...state,
        //         applyCouponError: null,
        //         applyCouponResult:action.result,
        //         applyCouponMessage: action.message,
        //         applyCouponLoading: false,
        //         isCouponApplied:true,

        //     };
        //     case actions.COUPON_APPLY_FAILED:
        //         return {
        //             ...state,
        //             applyCouponError: action.error,
        //             applyCouponResult:[],
        //             applyCouponMessage: null,
        //             applyCouponLoading: false,
        //             isCouponApplied:false,
        //         };
        //     case actions.COUPON_FORM_RESET:
        //     return {
        //         ...state,
        //         applyCouponError: null,
        //         applyCouponResult: [],
        //         applyCouponMessage: null,
        //         applyCouponLoading: false,
        //         isCouponApplied:false,

        //     };
        default:
            return state;
    }
}