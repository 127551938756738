const actions = {
    GET_SHORT_CODE_DATA: "GET_SHORT_CODE_DATA",
    GET_SHORT_CODE_DATA_SUCCESS: "GET_SHORT_CODE_DATA_SUCCESS",
    GET_SHORT_CODE_DATA_FAILED: "GET_SHORT_CODE_DATA_FAILED",

    getShortCodeData: (data) => ({
        type: actions.GET_SHORT_CODE_DATA,
        payload: { data }
    }),
    getShortCodeDataSuccessfull: (shortCodeResult) => ({
        type: actions.GET_SHORT_CODE_DATA_SUCCESS,
        shortCodeResult
    }),
    getShortCodeDataFailed: (shortCodeError) => ({
        type: actions.GET_SHORT_CODE_DATA_FAILED,
        shortCodeError
    }),

}
export default actions;