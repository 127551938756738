const actions = {
    GET_PLAN_LIST_BY_ICCID: 'GET_PLAN_LIST_BY_ICCID',
    GET_PLAN_LIST_BY_ICCID_SUCCESS: 'GET_PLAN_LIST_BY_ICCID_SUCCESS',
    GET_PLAN_LIST_BY_ICCID_FAILED: 'GET_PLAN_LIST_BY_ICCID_FAILED',
    RESET_ADD_PACKAGE: "RESET_ADD_PACKAGE",
    PURCHASE_PLAN_PACKAGE: "PURCHASE_PLAN_PACKAGE",
    PURCHASE_PLAN_PACKAGE_SUCCESS: "PURCHASE_PLAN_PACKAGE_SUCCESS",
    PURCHASE_PLAN_PACKAGE_PROCESSING: "PURCHASE_PLAN_PACKAGE_PROCESSING",
    PURCHASE_PLAN_PACKAGE_FAILED: "PURCHASE_PLAN_PACKAGE_FAILED",
    RESET_RECHARGE_PACKAGE_MESSAGE: "RESET_RECHARGE_PACKAGE_MESSAGE",

    getPlanListByICCID: (searchData) => ({
        type: actions.GET_PLAN_LIST_BY_ICCID,
        payload: { searchData }
    }),
    getPlanListByICCIDSuccess: (planPackageData) => ({
        type: actions.GET_PLAN_LIST_BY_ICCID_SUCCESS,
        planPackageData
    }),
    getPlanListByICCIDFailed: (planPackageError) => ({
        type: actions.GET_PLAN_LIST_BY_ICCID_FAILED,
        planPackageError
    }),
    resetAddPackage: () => ({
        type: actions.RESET_ADD_PACKAGE
    }),
    purchasePlanPackage: (rechargePackageData) => ({
        type: actions.PURCHASE_PLAN_PACKAGE,
        payload: { rechargePackageData }
    }),
    purchasePlanPackageSuccess: (rechargePackageSuccessMessage) => ({
        type: actions.PURCHASE_PLAN_PACKAGE_SUCCESS,
        rechargePackageSuccessMessage
    }),
    purchasePlanPackageProcessing: (rechargePackageProcessingMessage, planPackageProcessingData) => ({
        type: actions.PURCHASE_PLAN_PACKAGE_PROCESSING,
        rechargePackageProcessingMessage, planPackageProcessingData
    }),
    purchasePlanPackageFailed: (rechargePackageErrorMessage) => ({
        type: actions.PURCHASE_PLAN_PACKAGE_FAILED,
        rechargePackageErrorMessage
    }),
    resetRechargePlanPackageMessage: () => ({
        type: actions.RESET_RECHARGE_PACKAGE_MESSAGE
    })
};
export default actions;
