import actions from "./actions";

const initState = {
    trendingResult: [],
    loading: false,
    error: null,
    buttonLoading: false,
};

export default function reducer(state = initState, action) {
    switch (action.type) {
        case actions.GET_TRENDING_PLAN_REPORT:
            return {
                ...state,
                loading: true,
                error: null,
                buttonLoading :true
            };
        case actions.GET_TRENDING_PLAN_REPORT_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                trendingResult: action.trendingResult,
            };
        case actions.GET_TRENDING_PLAN_REPORT_FAILED:
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        case actions.RESET_TRENDING_PLAN_REPORT_MESSAGE:
            return {
                ...state,
                loading: false,
                error: null,
                buttonLoading: false,
            };
        default:
            return state;
    }
}