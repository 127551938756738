import actions from "./actions";

const initState = {
    regionResult: [],
    loading: false,
    regionError: null,
    regionMessage: null,
    buttonLoading: false,
    regionFormModal: false,
    regionCountryData: [],
    regionActiveResult:[]
};

export default function reducer(state = initState, action) {
    switch (action.type) {
        case actions.GET_REGION:
            return {
                ...state,
                loading: true,
                regionError: null,
                regionMessage: null,
                buttonLoading: false,
                regionFormModal: false
            };
        case actions.GET_REGION_SUCCESS:
            return {
                ...state,
                loading: false,
                regionError: null,
                regionResult: action.regionResult,
                regionMessage: null,
                buttonLoading: false,
                regionFormModal: false
            };
        case actions.GET_REGION_FAILED:
            return {
                ...state,
                loading: false,
                regionError: action.regionError,
                regionMessage: null,
                buttonLoading: false,
                regionFormModal: false
            };
        case actions.ADD_REGION:
            return {
                ...state,
                loading: false,
                regionError: null,
                regionMessage: null,
                buttonLoading: true,
                regionFormModal: true
            };
        case actions.ADD_REGION_SUCCESS:
            return {
                ...state,
                loading: false,
                regionResult: action.regionResult,
                regionMessage: action.regionMessage,
                regionError: null,
                buttonLoading: false,
                regionFormModal: false
            };
        case actions.ADD_REGION_FAILED:
            return {
                ...state,
                loading: false,
                regionMessage: null,
                regionError: action.regionError,
                buttonLoading: false,
                regionFormModal: true
            };
        case actions.EDIT_REGION:
            return {
                ...state,
                loading: false,
                regionError: null,
                regionMessage: null,
                buttonLoading: true,
                regionFormModal: true
            };
        case actions.EDIT_REGION_SUCCESS:
            return {
                ...state,
                loading: false,
                regionResult: action.regionResult,
                regionMessage: action.regionMessage,
                regionError: null,
                buttonLoading: false,
                regionFormModal: false
            };
        case actions.DELETE_REGION:
            return {
                ...state,
                loading: false,
                regionError: null,
                regionMessage: null,
                buttonLoading: false,
                regionFormModal: false
            };
        case actions.DELETE_REGION_SUCCESS:
            return {
                ...state,
                loading: false,
                regionMessage: action.regionMessage,
                regionResult: action.regionResult,
                regionError: null,
                buttonLoading: false,
                regionFormModal: false
            };
        case actions.PREPARE_REGION_FORM:
            return {
                ...state,
                loading: false,
                regionMessage: null,
                regionError: null,
                buttonLoading: false,
                regionFormModal: true
            };
        case actions.RESET_REGION:
            return {
                ...state,
                loading: false,
                regionMessage: null,
                regionError: null,
                buttonLoading: false,
                regionFormModal: false
            };
        case actions.GET_REGION_COUNTRY_RELATION:
            return {
                ...state,
                loading: true,
                regionMessage: null,
                regionError: null,
                buttonLoading: false,
                regionFormModal: false,
            };
        case actions.GET_REGION_COUNTRY_RELATION_SUCCESS:
            return {
                ...state,
                loading: false,
                regionMessage: null,
                regionError: null,
                buttonLoading: false,
                regionFormModal: false,
                regionCountryData: action.regionCountryData
            };
        case actions.RESET_REGION_MESSAGE:
            return {
                ...state,
                loading: false,
                regionMessage: null,
                regionError: null,
                buttonLoading: false,
            };

        case actions.GET_ACTIVE_REGION:
            return {
                ...state,
                regionError: null,
                regionMessage: null,
            }
        case actions.GET_ACTIVE_REGION_SUCCESS:
            return {
                ...state,      
                regionError: null,
                regionActiveResult: action.regionActiveResult,
                regionMessage: null,
            };
        case actions.GET_ACTIVE_REGION_FAILED:
            return {
                ...state,
                regionError: action.regionError,
                regionMessage: null,
            };
        default:
            return state;
    }
}