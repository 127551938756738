import actions from "./actions";

const initState = {
    list: [],
    modal: false,
    modalEditParam: false,
    loading: false,
    error: null,
    message: null,
    showOnCheckOutLoading: false,
};

export default function reducer(state = initState, action) {
    let listData;
    switch (action.type) {

        case actions.UPDATE_PAYMENT_GATEWAY_SHOW_ON_CHECKOUT:
            listData = state.list.map((item) => {
                let temp = item;
                if (action.payload.id == item.ID) {
                    temp.loading = !temp.loading
                }
                return temp;
            });
            return {
                ...state,
                showOnCheckOutLoading: true,
                error: null,
                message: null,
                list: listData
            };

        case actions.UPDATE_PAYMENT_GATEWAY_SHOW_ON_CHECKOUT_SUCCESS:
            listData = state.list.map((item) => {
                let temp = item;
                if (temp.loading) {
                    temp.loading = !temp.loading
                }
                return temp;
            });
            return {
                ...state,
                showOnCheckOutLoading: false,
                error: null,
                message: action.regionMessage,
                modal: false,
                modalEditParam: false,
                list: listData
            };

        case actions.UPDATE_PAYMENT_GATEWAY_SHOW_ON_CHECKOUT_FAILED:
            listData = state.list.map((item) => {
                let temp = item;
                if (temp.loading) {
                    temp.loading = !temp.loading
                }
                return temp;
            });
            return {
                ...state,
                showOnCheckOutLoading: false,
                error: action.error,
                message: null,
                list: listData
            };


        case actions.UPDATE_PAYMENT_GATEWAY_PARAMS:
            return {
                ...state,
                loading: true,
                error: null,
                message: null,
            };

        case actions.UPDATE_PAYMENT_GATEWAY_PARAMS_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                message: action.regionMessage,
                modal: false,
                modalEditParam: false,
            };

        case actions.UPDATE_PAYMENT_GATEWAY_PARAMS_FAILED:
            return {
                ...state,
                loading: false,
                error: action.error,
                message: null,
            };


        case actions.OPEN_EDIT_PARAM_PAYMENT_GATEWAY_MODAL:
            return {
                ...state,
                modalEditParam: true,
                error: null,
                message: null,
                loading: false,
            };
        case actions.CLOSE_EDIT_PARAM_PAYMENT_GATEWAY_MODAL:
            return {
                ...state,
                modalEditParam: false,
                error: null,
                message: null,
                loading: false,
            };

        case actions.GET_PAYMENT_GATEWAY:
            return {
                ...state,
                loading: true,
                error: null,
                message: null,
            };

        case actions.GET_PAYMENT_GATEWAY_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                list: action.data,
                modal: false,
            };

        case actions.GET_PAYMENT_GATEWAY_FAILED:
            return {
                ...state,
                loading: false,
                error: action.error,
                message: null,
            };

        case actions.OPEN_ADD_PAYMENT_GATEWAY_MODAL:
            return {
                ...state,
                modal: true,
                error: null,
                message: null,
                loading: false,
            };
        case actions.CLOSE_ADD_PAYMENT_GATEWAY_MODAL:
            return {
                ...state,
                modal: false,
                error: null,
                message: null,
                loading: false,
            };

        case actions.ADD_PAYMENT_GATEWAY:
            return {
                ...state,
                loading: true,
                error: null,
                message: null,
            };

        case actions.ADD_PAYMENT_GATEWAY_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                message: action.regionMessage,
                modal: false,
            };

        case actions.ADD_PAYMENT_GATEWAY_FAILED:
            return {
                ...state,
                loading: false,
                error: action.error,
                message: null,
            };

        default:
            return state;
    }
}