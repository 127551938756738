const actions = {
    
    // coupon part starts from here
    PREPARE_COUPON_FORM: "PREPARE_COUPON_FORM",
    RESET_COUPON_FORM: "RESET_COUPON_FORM",
    ADD_COUPON: "ADD_COUPON",
    ADD_COUPON_SUCCESS: "ADD_COUPON_SUCCESS",
    ADD_COUPON_FAILED: "ADD_COUPON_FAILED",
    GET_COUPON: "GET_COUPON",
    GET_COUPON_SUCCESS: "GET_COUPON_SUCCESS",
    GET_COUPON_FAILED: "GET_COUPON_FAILED",
    RESET_COUPON: "RESET_COUPON",
    DELETE_COUPON: "DELETE_COUPON",
    DELETE_COUPON_SUCCESS: "DELETE_COUPON_SUCCESS",
    EDIT_COUPON: "EDIT_COUPON",
    EDIT_COUPON_SUCCESS: "EDIT_COUPON_SUCCESS",
    COUPON_APPLY: "COUPON_APPLY",
    COUPON_APPLY_SUCCESS: "COUPON_APPLY_SUCCESS",
    COUPON_APPLY_FAILED: "COUPON_APPLY_FAILED",
    COUPON_FORM_RESET:"COUPON_FORM_RESET",
    // coupon part starts from here
    prepareCouponForm: () => ({
        type: actions.PREPARE_COUPON_FORM
    }),
    resetCouponForm: () => ({
        type: actions.RESET_COUPON_FORM
    }),
    addCoupon: (data) => ({
        type: actions.ADD_COUPON,
        payload: { data }
    }),
    addCouponSuccess: (regionMessage, regionResult) => ({
        type: actions.ADD_COUPON_SUCCESS,
        regionMessage, regionResult
    }),
    addCouponFailed: (error) => ({
        type: actions.ADD_COUPON_FAILED,
        error
    }),
    getCoupon: () => ({
        type: actions.GET_COUPON,
    }),
    getCouponSuccess: (message,data) => ({
        type: actions.GET_COUPON_SUCCESS,
        data
    }),
    getCouponFailed: (error) => ({
        type: actions.GET_COUPON_FAILED,
        error
    }),
    resetCoupon: () => ({
        type: actions.RESET_COUPON
    }),
    deleteCoupon: (couponId, status) => ({
        type: actions.DELETE_COUPON,
        payload: { couponId, status }
    }),
    deleteCouponSuccess: (regionMessage) => ({
        type: actions.DELETE_COUPON_SUCCESS,
        regionMessage
    }),
    editCoupon: (data) => ({
        type: actions.EDIT_COUPON,
        payload: { data }
    }),
    editCouponSuccess: (regionMessage, regionResult) => ({
        type: actions.EDIT_COUPON_SUCCESS,
        regionMessage, regionResult
    }),

    // applyCoupon: (data) => ({
    //     type: actions.COUPON_APPLY,
    //     payload: { data }
    // }),
    // applyCouponSuccess: (message, result) => ({
    //     type: actions.COUPON_APPLY_SUCCESS,
    //     message, result
    // }),
    // applyCouponFailed: (error) => ({
    //     type: actions.COUPON_APPLY_FAILED,
    //     error
    // }),
    // resetFormCoupon: () => ({
    //     type: actions.COUPON_FORM_RESET,
    // }),

}
export default actions;