import actions from "./actions";

const initState = {
    planPackageData: [],
    loading: false,
    planPackageError: null,
    planPackageMessage: null,
    showTableBox: false,
    planPackageProcessingData: [],
    rechargePackageSuccessMessage: null,
    rechargePackageErrorMessage: null,
    rechargePackageProcessingMessage: null,
    buttonLoading: false
};

export default function reducer(state = initState, action) {
    switch (action.type) {
        case actions.GET_PLAN_LIST_BY_ICCID:
            return {
                ...state,
                loading: true,
                showTableBox: true
            };
        case actions.GET_PLAN_LIST_BY_ICCID_SUCCESS:
            return {
                ...state,
                loading: false,
                planPackageError: null,
                planPackageData: action.planPackageData,
                showTableBox: true
            };
        case actions.GET_PLAN_LIST_BY_ICCID_FAILED:
            return {
                ...state,
                loading: false,
                planPackageError: action.planPackageError,
                showTableBox: true
            };
        case actions.RESET_ADD_PACKAGE:
            return {
                ...state,
                loading: false,
                planPackageError: null,
                showTableBox: false
            };
        case actions.PURCHASE_PLAN_PACKAGE:
            return {
                ...state,
                loading: true,
                planPackageError: null,
                showTableBox: true,
                buttonLoading: true
            };
        case actions.PURCHASE_PLAN_PACKAGE_SUCCESS:
            return {
                ...state,
                loading: true,
                planPackageError: null,
                rechargePackageSuccessMessage: action.rechargePackageSuccessMessage,
                showTableBox: true,
                buttonLoading: false
            };
        case actions.PURCHASE_PLAN_PACKAGE_PROCESSING:
            return {
                ...state,
                loading: true,
                planPackageError: null,
                showTableBox: true,
                planPackageProcessingData: action.planPackageProcessingData,
                rechargePackageProcessingMessage: action.rechargePackageProcessingMessage,
                buttonLoading: false
            };
        case actions.PURCHASE_PLAN_PACKAGE_FAILED:
            return {
                ...state,
                loading: true,
                planPackageError: null,
                showTableBox: true,
                rechargePackageErrorMessage: action.rechargePackageErrorMessage,
                buttonLoading: false
            };
        case actions.RESET_RECHARGE_PACKAGE_MESSAGE:
            return {
                ...state,
                loading: false,
                planPackageError: null,
                showTableBox: false,
                rechargePackageSuccessMessage: null,
                rechargePackageErrorMessage: null,
                rechargePackageProcessingMessage: null,
                buttonLoading: false
            };

        default:
            return state;
    }
}
