const actions = {
    GET_INVENTORY: 'GET_INVENTORY',
    GET_INVENTORY_SUCCESS: 'GET_INVENTORY_SUCCESS',
    GET_INVENTORY_FAILED: 'GET_INVENTORY_FAILED',
    TO_CHECK_DATA_USAGE: "TO_CHECK_DATA_USAGE",
    TO_CHECK_DATA_USAGE_SUCCESS: "TO_CHECK_DATA_USAGE_SUCCESS",
    TO_CHECK_DATA_USAGE_FAILED: "TO_CHECK_DATA_USAGE_FAILED",
    RESET_INVENTORY_MESSAGE: "RESET_INVENTORY_MESSAGE",
    SHOW_VIEW_MODAL: 'SHOW_VIEW_MODAL',
    CLOSE_VIEW_MODAL: 'CLOSE_VIEW_MODAL',


    showViewModal: () => ({
        type: actions.SHOW_VIEW_MODAL,
    }),
    closeViewModal: () => ({
        type: actions.CLOSE_VIEW_MODAL,
    }),

    getInventory: () => ({
        type: actions.GET_INVENTORY,
    }),
    getInventorySuccess: (inventoryResult) => ({
        type: actions.GET_INVENTORY_SUCCESS,
        inventoryResult
    }),
    getInventoryFailed: (inventoryError) => ({
        type: actions.GET_INVENTORY_FAILED,
        inventoryError
    }),

    toCheckDataUsage: (data) => ({
        type: actions.TO_CHECK_DATA_USAGE,
        payload: { data }
    }),
    toCheckDataUsageSuccess: (dataUsageResult) => ({
        type: actions.TO_CHECK_DATA_USAGE_SUCCESS,
        dataUsageResult
    }),
    toCheckDataUsageFailed: (inventoryError) => ({
        type: actions.TO_CHECK_DATA_USAGE_FAILED,
        inventoryError
    }),
    
    resetInventoryMessage: () => ({
        type: actions.RESET_INVENTORY_MESSAGE
    })
};
export default actions;
