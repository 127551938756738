const actions = {
    UPLOAD_ESIM_GO_PLAN_RESET: "UPLOAD_ESIM_GO_PLAN_RESET",
    UPLOAD_ESIM_GO_PLAN: "UPLOAD_ESIM_GO_PLAN",
    UPLOAD_ESIM_GO_PLAN_SUCCESS: "UPLOAD_ESIM_GO_PLAN_SUCCESS",
    UPLOAD_ESIM_GO_PLAN_FAILED: "UPLOAD_ESIM_GO_PLAN_FAILED",
    GET_ESIM_GO_PLAN: "GET_ESIM_GO_PLAN",
    GET_ESIM_GO_PLAN_SUCCESS: "GET_ESIM_GO_PLAN_SUCCESS",
    GET_ESIM_GO_PLAN_FAILED: "GET_ESIM_GO_PLAN_FAILED",

    getEsimGoPlan: () => ({
        type: actions.GET_ESIM_GO_PLAN,
    }),
    getEsimGoPlanSuccess: (data) => ({
        type: actions.GET_ESIM_GO_PLAN_SUCCESS,
        data
    }),
    getEsimGoPlanFailed: (error) => ({
        type: actions.GET_ESIM_GO_PLAN_FAILED,
        error
    }),

    uploadEsimGoPlan: (data) => ({
        type: actions.UPLOAD_ESIM_GO_PLAN,
        payload: { data }
    }),
    uploadEsimGoPlanSuccess: (message) => ({
        type: actions.UPLOAD_ESIM_GO_PLAN_SUCCESS,
        message
    }),
    uploadEsimGoPlanFailed: (error) => ({
        type: actions.UPLOAD_ESIM_GO_PLAN_FAILED,
        error
    }),
    resetUploadEsimGoPlan: () => ({
        type: actions.UPLOAD_ESIM_GO_PLAN_RESET,
    }),

}
export default actions;