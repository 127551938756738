import { all, takeEvery, put, call } from 'redux-saga/effects';
import actions from './actions';
import { getList, addData, editData, deleteData } from '../../helpers/fackBackend_Helper';

function* getNetworkListResponse() {
    try {
        const response = yield call(getList, '/networks');
        if (response.response.Status) {
            yield put(
                actions.getNetworkSuccess(
                    response.response.Data
                )
            );
        } else {
            yield put(actions.getNetworkFailed());
        }
    } catch (error) {
        yield put(actions.getNetworkFailed());
    }
}

function* addNetworkResponse({ payload: { data } }) {
    try {
        const response = yield call(addData, '/createUpdateNetwork', data);
        if (response.response.Status) {
            const responseList = yield call(getList, '/networks');
            if (responseList.response.Status) {
                yield put(
                    actions.addNetworkSuccess(
                        response.response.Message, responseList.response.Data
                    )
                );
            } else {
                yield put(actions.addNetworkFailed(
                    response.response.Message
                ));
            }
        } else {
            yield put(actions.addNetworkFailed(
                response.response.Message
            ));
        }
    } catch (error) {
        yield put(actions.addNetworkFailed(error));
    }
}

function* editNetworkResponse({ payload: { data } }) {
    try {
        const response = yield call(addData, '/createUpdateNetwork', data);
        if (response.response.Status) {
            const responseList = yield call(getList, '/networks');
            if (responseList.response.Status) {
                yield put(
                    actions.editNetworkSuccess(
                        response.response.Message, responseList.response.Data
                    )
                );
            } else {
                yield put(actions.addNetworkFailed(
                    response.response.Message
                ));
            }
        } else {
            yield put(actions.addNetworkFailed(
                response.response.Message
            ));
        }
    } catch (error) {
        yield put(actions.addNetworkFailed(error));
    }
}

function* deleteNetworkResponse({ payload: { networkId, status } }) {
    try {
        const response = yield call(deleteData, '/deleteNetwork/' + networkId + '/' + status);
        if (response.response.Status) {
            const responseList = yield call(getList, '/networks');
            if (responseList.response.Status) {
                yield put(
                    actions.deleteNetworkSuccess(
                        response.response.Message, responseList.response.Data
                    )
                );
            } else {
                yield put(actions.getNetworkFailed(
                    response.response.Message
                ));
            }
        } else {
            yield put(actions.getNetworkFailed(
                response.response.Message
            ));
        }
    } catch (error) {
        yield put(actions.addNetworkFailed(error));
    }
}

function* getActiveNetworkListResponse() {
    try {
        const response = yield call(getList, '/activeNetworkList');
        if (response.response.Status) {
            yield put( actions.getActiveNetworkSuccess( response.response.Data ) );
        } else {
            yield put(actions.getActiveNetworkFailed());
        }
    } catch (error) {
        yield put(actions.getActiveNetworkFailed());
    }
}

export default function* rootSaga() {
    yield all([takeEvery(actions.GET_NETWORK, getNetworkListResponse)]);
    yield all([takeEvery(actions.ADD_NETWORK, addNetworkResponse)]);
    yield all([takeEvery(actions.DELETE_NETWORK, deleteNetworkResponse)]);
    yield all([takeEvery(actions.EDIT_NETWORK, editNetworkResponse)]);
    yield all([takeEvery(actions.GET_ACTIVE_NETWORK, getActiveNetworkListResponse)]);
}