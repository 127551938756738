const actions = {
    OPEN_ADD_PAYMENT_GATEWAY_MODAL: "OPEN_ADD_PAYMENT_GATEWAY_MODAL",
    CLOSE_ADD_PAYMENT_GATEWAY_MODAL: "CLOSE_ADD_PAYMENT_GATEWAY_MODAL",
    ADD_PAYMENT_GATEWAY: "ADD_PAYMENT_GATEWAY",
    ADD_PAYMENT_GATEWAY_SUCCESS: "ADD_PAYMENT_GATEWAY_SUCCESS",
    ADD_PAYMENT_GATEWAY_FAILED: "ADD_PAYMENT_GATEWAY_FAILED",
    GET_PAYMENT_GATEWAY: "GET_PAYMENT_GATEWAY",
    GET_PAYMENT_GATEWAY_SUCCESS: "GET_PAYMENT_GATEWAY_SUCCESS",
    GET_PAYMENT_GATEWAY_FAILED: "GET_PAYMENT_GATEWAY_FAILED",
    OPEN_EDIT_PARAM_PAYMENT_GATEWAY_MODAL: "OPEN_EDIT_PARAM_PAYMENT_GATEWAY_MODAL",
    CLOSE_EDIT_PARAM_PAYMENT_GATEWAY_MODAL: "CLOSE_EDIT_PARAM_PAYMENT_GATEWAY_MODAL",
    UPDATE_PAYMENT_GATEWAY_PARAMS: "UPDATE_PAYMENT_GATEWAY_PARAMS",
    UPDATE_PAYMENT_GATEWAY_PARAMS_SUCCESS: "UPDATE_PAYMENT_GATEWAY_PARAMS_SUCCESS",
    UPDATE_PAYMENT_GATEWAY_PARAMS_FAILED: "UPDATE_PAYMENT_GATEWAY_PARAMS_FAILED",
    UPDATE_PAYMENT_GATEWAY_SHOW_ON_CHECKOUT: "UPDATE_PAYMENT_GATEWAY_SHOW_ON_CHECKOUT",
    UPDATE_PAYMENT_GATEWAY_SHOW_ON_CHECKOUT_SUCCESS: "UPDATE_PAYMENT_GATEWAY_SHOW_ON_CHECKOUT_SUCCESS",
    UPDATE_PAYMENT_GATEWAY_SHOW_ON_CHECKOUT_FAILED: "UPDATE_PAYMENT_GATEWAY_SHOW_ON_CHECKOUT_FAILED",


    updatePaymentGatewayShowOnCheckout: (id, flag) => ({
        type: actions.UPDATE_PAYMENT_GATEWAY_SHOW_ON_CHECKOUT,
        payload: { id, flag }
    }),
    updatePaymentGatewayShowOnCheckoutSuccess: (regionMessage) => ({
        type: actions.UPDATE_PAYMENT_GATEWAY_SHOW_ON_CHECKOUT_SUCCESS,
        regionMessage
    }),
    updatePaymentGatewayShowOnCheckoutFailed: (error) => ({
        type: actions.UPDATE_PAYMENT_GATEWAY_SHOW_ON_CHECKOUT_FAILED,
        error
    }),


    updatePaymentGatewayParams: (id, data) => ({
        type: actions.UPDATE_PAYMENT_GATEWAY_PARAMS,
        payload: { id, data }
    }),
    updatePaymentGatewayParamsSuccess: (regionMessage, regionResult) => ({
        type: actions.UPDATE_PAYMENT_GATEWAY_PARAMS_SUCCESS,
        regionMessage, regionResult
    }),
    updatePaymentGatewayParamsFailed: (error) => ({
        type: actions.UPDATE_PAYMENT_GATEWAY_PARAMS_FAILED,
        error
    }),

    openEditParamPaymentGatewayModal: () => ({
        type: actions.OPEN_EDIT_PARAM_PAYMENT_GATEWAY_MODAL,
    }),
    closeEditParamPaymentGatewayModal: () => ({
        type: actions.CLOSE_EDIT_PARAM_PAYMENT_GATEWAY_MODAL,
    }),
    getPaymentGateway: () => ({
        type: actions.GET_PAYMENT_GATEWAY,
    }),
    getPaymentGatewaySuccess: (data) => ({
        type: actions.GET_PAYMENT_GATEWAY_SUCCESS,
        data
    }),
    getPaymentGatewayFailed: (error) => ({
        type: actions.GET_PAYMENT_GATEWAY_FAILED,
        error
    }),
    openAddPaymentGatewayModal: () => ({
        type: actions.OPEN_ADD_PAYMENT_GATEWAY_MODAL,
    }),
    closeAddPaymentGatewayModal: () => ({
        type: actions.CLOSE_ADD_PAYMENT_GATEWAY_MODAL,
    }),
    addPaymentGateway: (data) => ({
        type: actions.ADD_PAYMENT_GATEWAY,
        payload: { data }
    }),
    addPaymentGatewaySuccess: (regionMessage, regionResult) => ({
        type: actions.ADD_PAYMENT_GATEWAY_SUCCESS,
        regionMessage, regionResult
    }),
    addPaymentGatewayFailed: (error) => ({
        type: actions.ADD_PAYMENT_GATEWAY_FAILED,
        error
    }),
}
export default actions;