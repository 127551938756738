import actions from "./actions";

const initState = {
    list: [],
    modal: false,
    modalEditParam: false,
    loading: false,
    error: null,
    message: null,
};

export default function reducer(state = initState, action) {
    switch (action.type) {
        
        case actions.UPDATE_TRAFFIC_POLICY:
            return {
                ...state,
                loading: true,
                error: null,
                message: null,
            };

        case actions.UPDATE_TRAFFIC_POLICY_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                message: action.message,
                modal: false,
            };

        case actions.UPDATE_TRAFFIC_POLICY_FAILED:
            return {
                ...state,
                loading: false,
                error: action.error,
                message: null,
                modal: false,
            };

        case actions.TRAFFIC_POLICY_STATUS_CHANGE:
            return {
                ...state,
                loading: true,
                error: null,
                message: null,
            };

        case actions.TRAFFIC_POLICY_STATUS_CHANGE_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                message: action.message,
                modal: false,
            };

        case actions.TRAFFIC_POLICY_STATUS_CHANGE_FAILED:
            return {
                ...state,
                loading: false,
                error: action.error,
                message: null,
                modal: false,
            };


        case actions.GET_TRAFFIC_POLICY_CRONUS:
            return {
                ...state,
                loading: true,
                error: null,
                message: null,
            };

        case actions.GET_TRAFFIC_POLICY_CRONUS_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                list: action.data,
                modal: false,
            };

        case actions.GET_TRAFFIC_POLICY_CRONUS_FAILED:
            return {
                ...state,
                loading: false,
                error: action.error,
                message: null,
            };


        case actions.ADD_TRAFFIC_POLICY:
            return {
                ...state,
                loading: true,
                error: null,
                message: null,
            };

        case actions.ADD_TRAFFIC_POLICY_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                message: action.message,
                modal: false,
            };

        case actions.ADD_TRAFFIC_POLICY_FAILED:
            return {
                ...state,
                loading: false,
                error: action.error,
                message: null,
                modal: false,
            };


        case actions.OPEN_ADD_TRAFFIC_POLICY_MODAL:
            return {
                ...state,
                modal: true,
                error: null,
                message: null,
                loading: false,
            };
        case actions.CLOSE_ADD_TRAFFIC_POLICY_MODAL:
            return {
                ...state,
                modal: false,
                error: null,
                message: null,
                loading: false,
            };


        /******************************************/
        
        default:
            return state;
    }
}