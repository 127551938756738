const actions = {
    GET_DATA_LIST_TO_REFUND: "GET_DATA_LIST_TO_REFUND",
    GET_DATA_LIST_TO_REFUND_SUCCESS: "GET_DATA_LIST_TO_REFUND_SUCCESS",
    GET_DATA_LIST_TO_REFUND_FAILED: "GET_DATA_LIST_TO_REFUND_FAILED",

    TO_REFUND_REQUEST: "TO_REFUND_REQUEST",
    TO_REFUND_REQUEST_SUCCESS: "TO_REFUND_REQUEST_SUCCESS",
    TO_REFUND_REQUEST_FAILED: "TO_REFUND_REQUEST_FAILED",
    TO_RESET_REFUND: "TO_RESET_REFUND",
    TO_RESET_REFUND_MESSAGE:"TO_RESET_REFUND_MESSAGE",

    getDataListToRefund: (data) => ({
        type: actions.GET_DATA_LIST_TO_REFUND,
        payload: { data }
    }),
    getDataListToRefundSuccess: (refundDataList) => ({
        type: actions.GET_DATA_LIST_TO_REFUND_SUCCESS,
        refundDataList
    }),
    getDataListToRefundFailed: (refundDataListError) => ({
        type: actions.GET_DATA_LIST_TO_REFUND_FAILED,
        refundDataListError

    }),
    toRefundRequest: (refundData) => ({
        type: actions.TO_REFUND_REQUEST,
        payload: { refundData }
    }),
    toRefundRequestSuccess: (refundDataListMessage, refundDataList) => ({
        type: actions.TO_REFUND_REQUEST_SUCCESS,
        refundDataListMessage,
        refundDataList
    }),
    toRefundRequestFailed: (refundDataListError) => ({
        type: actions.TO_REFUND_REQUEST_FAILED,
        refundDataListError
    }),
    toResetRefund: () => ({
        type: actions.TO_RESET_REFUND
    }),
    toResetRefundMessage:()=>({
        type:actions.TO_RESET_REFUND_MESSAGE
    })
}
export default actions;
