import actions from "./actions";

const initState = {
    list: [],
    modal: false,
    modalEditParam: false,
    loading: false,
    error: null,
    message: null,
    // new for update page
    paramsPeview: false,
    previewData: [],
    activelist:[]
};

export default function reducer(state = initState, action) {
    let newPreviewData;
    switch (action.type) {

        case actions.GET_AGGREGATOR_DATA:
            return {
                ...state,
                previewData: action.payload.data,
            };


        case actions.SET_PREVIEW_EDIT:
            newPreviewData = state.previewData.map((item) => {
                let temp = item;
                if (item.id == action.payload.id) {
                    temp.editable = !temp.editable;
                } else {
                    temp.editable = false;
                }
                return temp;
            });

            return {
                ...state,
                previewData: newPreviewData,
            };

        case actions.SET_PREVIEW_DATA:
            return {
                ...state,
                previewData: action.payload.data,
            };


        case actions.HIDE_PARAMS_PREVIEW:
            return {
                ...state,
                paramsPeview: false,
            };

        case actions.SHOW_PARAMS_PREVIEW:
            return {
                ...state,
                paramsPeview: true,
            };

        case actions.UPDATE_AGGREGATOR_PARAMS:
            return {
                ...state,
                loading: true,
                error: null,
                message: null,
            };

        case actions.UPDATE_AGGREGATOR_PARAMS_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                message: action.regionMessage,
                modal: false,
                modalEditParam: false,
            };

        case actions.UPDATE_AGGREGATOR_PARAMS_FAILED:
            return {
                ...state,
                loading: false,
                error: action.error,
                message: null,
            };


        case actions.OPEN_EDIT_PARAM_AGGREGATOR_MODAL:
            return {
                ...state,
                modalEditParam: true,
                error: null,
                message: null,
                loading: false,
            };
        case actions.CLOSE_EDIT_PARAM_AGGREGATOR_MODAL:
            return {
                ...state,
                modalEditParam: false,
                error: null,
                message: null,
                loading: false,
            };

        case actions.GET_AGGREGATOR:
            return {
                ...state,
                loading: true,
                error: null,
                message: null,
            };

        case actions.GET_AGGREGATOR_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                list: action.data,
                modal: false,
            };

        case actions.GET_AGGREGATOR_FAILED:
            return {
                ...state,
                loading: false,
                error: action.error,
                message: null,
            };

        case actions.OPEN_ADD_AGGREGATOR_MODAL:
            return {
                ...state,
                modal: true,
                error: null,
                message: null,
                loading: false,
            };
        case actions.CLOSE_ADD_AGGREGATOR_MODAL:
            return {
                ...state,
                modal: false,
                error: null,
                message: null,
                loading: false,
            };

        case actions.ADD_AGGREGATOR:
            return {
                ...state,
                loading: true,
                error: null,
                message: null,
            };

        case actions.ADD_AGGREGATOR_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                message: action.regionMessage,
                modal: false,
            };

        case actions.ADD_AGGREGATOR_FAILED:
            return {
                ...state,
                loading: false,
                error: action.error,
                message: null,
            };

            case actions.GET_AGGREGATOR_ACTIVE:
                return {
                    ...state,
                    loading: true,
                    error: null,
                    message: null,
                };
    
            case actions.GET_AGGREGATOR_ACTIVE_SUCCESS:
                return {
                    ...state,
                    loading: false,
                    error: null,
                    activelist: action.data,
                    modal: false,
                };
    
            case actions.GET_AGGREGATOR_ACTIVE_FAILED:
                return {
                    ...state,
                    loading: false,
                    error: action.error,
                    message: null,
                };
        default:
            return state;
    }
}