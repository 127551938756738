const actions = {
    GET_REGION: 'GET_REGION',
    GET_REGION_SUCCESS: 'GET_REGION_SUCCESS',
    GET_REGION_FAILED: 'GET_REGION_FAILED',
    ADD_REGION: "ADD_REGION",
    ADD_REGION_SUCCESS: "ADD_REGION_SUCCESS",
    ADD_REGION_FAILED: "ADD_REGION_FAILED",
    DELETE_REGION: "DELETE_REGION",
    DELETE_REGION_SUCCESS: "DELETE_REGION_SUCCESS",
    EDIT_REGION: "EDIT_REGION",
    EDIT_REGION_SUCCESS: "EDIT_REGION_SUCCESS",
    RESET_REGION: "RESET_REGION",
    PREPARE_REGION_FORM: "PREPARE_REGION_FORM",
    GET_REGION_COUNTRY_RELATION: "GET_REGION_COUNTRY_RELATION",
    GET_REGION_COUNTRY_RELATION_SUCCESS: "GET_REGION_COUNTRY_RELATION_SUCCESS",
    RESET_REGION_MESSAGE: "RESET_REGION_MESSAGE",
    GET_ACTIVE_REGION: 'GET_ACTIVE_REGION',
    GET_ACTIVE_REGION_SUCCESS: 'GET_ACTIVE_REGION_SUCCESS',
    GET_ACTIVE_REGION_FAILED: 'GET_ACTIVE_REGION_FAILED',

    getRegion: () => ({
        type: actions.GET_REGION,
    }),
    getRegionSuccess: (regionResult) => ({
        type: actions.GET_REGION_SUCCESS,
        regionResult
    }),
    getRegionFailed: (regionError) => ({
        type: actions.GET_REGION_FAILED,
        regionError
    }),
    addRegion: (data) => ({
        type: actions.ADD_REGION,
        payload: { data }
    }),
    addRegionSuccess: (regionMessage, regionResult) => ({
        type: actions.ADD_REGION_SUCCESS,
        regionMessage, regionResult
    }),
    addRegionFailed: (regionError) => ({
        type: actions.ADD_REGION_FAILED,
        regionError
    }),
    deleteRegion: (regionId, status) => ({
        type: actions.DELETE_REGION,
        payload: { regionId, status }
    }),
    deleteRegionSuccess: (regionMessage, regionResult) => ({
        type: actions.DELETE_REGION_SUCCESS,
        regionMessage, regionResult
    }),
    resetRegion: () => ({
        type: actions.RESET_REGION
    }),
    editRegion: (data) => ({
        type: actions.EDIT_REGION,
        payload: { data }
    }),
    editRegionSuccess: (regionMessage, regionResult) => ({
        type: actions.EDIT_REGION_SUCCESS,
        regionMessage, regionResult
    }),
    prepareRegionForm: () => ({
        type: actions.PREPARE_REGION_FORM
    }),
    getRegionCountryRelation: (regionId) => ({
        type: actions.GET_REGION_COUNTRY_RELATION,
        payload: { regionId }
    }),
    getCountryRegionRelationSuccessFull: (regionCountryData) => ({
        type: actions.GET_REGION_COUNTRY_RELATION_SUCCESS,
        regionCountryData
    }),
    resetRegionMessage: () => ({
        type: actions.RESET_REGION_MESSAGE
    }),

    getActiveRegion: () => ({
        type: actions.GET_ACTIVE_REGION,
    }),
    getActiveRegionSuccess: (regionActiveResult) => ({
        type: actions.GET_ACTIVE_REGION_SUCCESS,
        regionActiveResult
    }),
    getActiveRegionFailed: (regionError) => ({
        type: actions.GET_ACTIVE_REGION_FAILED,
        regionError
    }),

}
export default actions;