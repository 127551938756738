import { all, takeEvery, put, call } from 'redux-saga/effects';
import actions from './actions';
import { getList } from '../../helpers/fackBackend_Helper';

function* getCountryListResponse() {
    
    try {
        const response = yield call(getList, '/country');
        if (response.response.status = 1) {
            
            yield put(
                actions.getCountrySuccess(
                    response.response.data
                )
            );
        } else {
            
            yield put(actions.getCountryFailed());
        }
    } catch (error) {
        yield put(actions.getCountryFailed());
    }
}
export default function* rootSaga() {
    yield all([takeEvery(actions.GET_COUNTRY, getCountryListResponse)]);
}
