const actions = {
  CHECK_AUTHORIZATION: 'CHECK_AUTHORIZATION',
  LOGIN_REQUEST: 'LOGIN_REQUEST',
  LOGOUT: 'LOGOUT',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGIN_ERROR: 'LOGIN_ERROR',
  RESET_LOGIN_ERROR: "RESET_LOGIN_ERROR",
  CHANGE_PASSWORD: "CHANGE_PASSWORD",
  CHANGE_PASSWORD_SUCCESS: "CHANGE_PASSWORD_SUCCESS",
  CHANGE_PASSWORD_FAILED: "CHANGE_PASSWORD_FAILED",
  GET_USER_DATA_BY_TOKEN: "GET_USER_DATA_BY_TOKEN",
  GET_USER_DATA_BY_TOKEN_SUCCESS: "GET_USER_DATA_BY_TOKEN_SUCCESS",
  GET_USER_DATA_BY_TOKEN_FAILED: "GET_USER_DATA_BY_TOKEN_FAILED",
  PREPARE_CHANGE_PASSWORD_FORM: "PREPARE_CHANGE_PASSWORD_FORM",
  RESET_LOGIN_MESSAGE: "RESET_LOGIN_MESSAGE",
  VERIFY_EMAIL_ON_FORGET_PASSOWRD: "VERIFY_EMAIL_ON_FORGET_PASSOWRD",
  VERIFY_EMAIL_ON_FORGET_PASSOWRD_SUCCESS: "VERIFY_EMAIL_ON_FORGET_PASSOWRD_SUCCESS",
  VERIFY_EMAIL_ON_FORGET_PASSWORD_FAILED: "VERIFY_EMAIL_ON_FORGET_PASSWORD_FAILED",
  RESET_FORGET_PASSWORD: "RESET_FORGET_PASSWORD",
  RESET_FORGET_PASSWORD_SUCCESS: "RESET_FORGET_PASSWORD_SUCCESS",
  RESET_FORGET_PASSWORD_FAILED: "RESET_FORGET_PASSWORD_FAILED",
  checkAuthorization: () => ({ type: actions.CHECK_AUTHORIZATION }),
  login: (loginData, token) => ({
    type: actions.LOGIN_REQUEST,
    payload: { loginData, token },
  }),
  logout: () => ({
    type: actions.LOGOUT,
  }),
  loginError: (loginError) => ({
    type: actions.LOGIN_ERROR,
    payload: { loginError },
  }),
  resetLoginError: () => ({
    type: actions.RESET_LOGIN_ERROR
  }),
  changePassword: (passwordData) => ({
    type: actions.CHANGE_PASSWORD,
    payload: { passwordData },
  }),
  changePasswordSuccess: (loginMessage) => ({
    type: actions.CHANGE_PASSWORD_SUCCESS,
    loginMessage
  }),
  changePasswordFailed: (loginError) => ({
    type: actions.CHANGE_PASSWORD_FAILED,
    loginError
  }),
  getUserDataByToken: () => ({
    type: actions.GET_USER_DATA_BY_TOKEN,
  }),
  getUserDataByTokenSuccess: (tokenData) => ({
    type: actions.GET_USER_DATA_BY_TOKEN_SUCCESS,
    tokenData
  }),
  getUserDataByTokenError: (tokenError) => ({
    type: actions.GET_USER_DATA_BY_TOKEN_FAILED,
    tokenError
  }),
  prepareChangePasswordForm: () => ({
    type: actions.PREPARE_CHANGE_PASSWORD_FORM,
  }),
  resetLoginMessage: () => ({
    type: actions.RESET_LOGIN_MESSAGE
  }),
  verifyEmail: (data) => ({
    type: actions.VERIFY_EMAIL_ON_FORGET_PASSOWRD,
    payload: { data },
  }),
  verifyEmailSuccess: (loginMessage) => ({
    type: actions.VERIFY_EMAIL_ON_FORGET_PASSOWRD_SUCCESS,
    loginMessage
  }),
  verifyEmailFailed: (loginError) => ({
    type: actions.VERIFY_EMAIL_ON_FORGET_PASSWORD_FAILED,
    loginError
  }),
  resetForgetPassword: (data) => ({
    type: actions.RESET_FORGET_PASSWORD,
    payload: { data },
  }),
  resetForgetPasswordSuccess: (loginMessage) => ({
    type: actions.RESET_FORGET_PASSWORD_SUCCESS,
    loginMessage
  }),
  resetForgetPasswordFailed: (loginError) => ({
    type: actions.RESET_FORGET_PASSWORD_FAILED,
    loginError
  }),

};
export default actions;
