const actions = {
    GET_ORDER_LIST: "GET_ORDER_LIST",
    GET_ORDER_LIST_SUCCESS: "GET_ORDER_LIST_SUCCESS",
    GET_ORDER_LIST_FAILED: "GET_ORDER_LIST_FAILED",
    GET_ORDER_DETAILS_LIST: "GET_ORDER_DETAILS_LIST",
    GET_ORDER_DETAILS_LIST_SUCCESS: "GET_ORDER_DETAILS_LIST_SUCCESS",
    GET_ORDER_DETAILS_LIST_FAILED: "GET_ORDER_DETAILS_LIST_FAILED",

    OPEN_ORDER_MODAL: "OPEN_ORDER_MODAL_LIST",
    OPEN_ORDER_MODAL_SUCCESS: "OPEN_ORDER_MODAL_SUCCESS_LIST",
    CLOSE_ORDER_MODAL: "CLOSE_ORDER_MODAL_LIST",

    openOrderModal: (orderID) => ({
        type: actions.OPEN_ORDER_MODAL,
        payload: { orderID }
    }),
    openOrderModalSuccess: (data, orderID) => ({
        type: actions.OPEN_ORDER_MODAL_SUCCESS,
        data,
        orderID
    }),
    closeOrderModal: () => ({
        type: actions.CLOSE_ORDER_MODAL
    }),

    getOrderList: (data) => ({
        type: actions.GET_ORDER_LIST,
        payload: { data }
    }),
    getOrderListSuccess: (orderListResult) => ({
        type: actions.GET_ORDER_LIST_SUCCESS,
        orderListResult
    }),
    getOrderListFailed: (orderListError) => ({
        type: actions.GET_ORDER_LIST_FAILED,
        orderListError
    }),
    getOrderDetails: (orderId) => ({
        type: actions.GET_ORDER_DETAILS_LIST,
        payload: { orderId }
    }),
    getOrderDetailsSuccess: (orderDetailsResult) => ({
        type: actions.GET_ORDER_DETAILS_LIST_SUCCESS,
        orderDetailsResult
    }),
    getOrderDetailsFailed: (orderListError) => ({
        type: actions.GET_ORDER_DETAILS_LIST_FAILED,
        orderListError
    }),
}
export default actions;