import actions from "./actions";

const initState = {
    inventoryResult: [],
    loading: false,
    modalLoading: false,
    inventoryError: null,
    inventoryMessage: null,
    dataUsageResult: [],
    showModal: false,
};

export default function reducer(state = initState, action) {
    switch (action.type) {
        case actions.GET_INVENTORY:
            return {
                ...state,
                loading: true,
                inventoryMessage: null,
                inventoryError: null,
            };
        case actions.GET_INVENTORY_SUCCESS:
            return {
                ...state,
                loading: false,
                inventoryResult: action.inventoryResult,
                inventoryMessage: null,
                inventoryError: null,
            };
        case actions.GET_INVENTORY_FAILED:
            return {
                ...state,
                loading: false,
                inventoryError: action.inventoryError,
                inventoryMessage: null,
            };

        case actions.TO_CHECK_DATA_USAGE:
            return {
                ...state,
                modalLoading: true,
                inventoryMessage: null,
                inventoryError: null,
                dataUsageResult: [],
            };
        case actions.TO_CHECK_DATA_USAGE_SUCCESS:
            return {
                ...state,
                modalLoading: false,
                dataUsageResult: action.dataUsageResult,
                inventoryMessage: null,
                inventoryError: null,
            };
        case actions.TO_CHECK_DATA_USAGE_FAILED:
            return {
                ...state,
                modalLoading: false,
                inventoryError: action.inventoryError,
                inventoryMessage: null,
            };

        case actions.RESET_INVENTORY_MESSAGE:
            return {
                ...state,
                modalLoading: false,
                inventoryError: null,
                inventoryMessage: null
            }

        case actions.SHOW_VIEW_MODAL:
            return {
                ...state,
                showModal: true,
            }
        case actions.CLOSE_VIEW_MODAL:
            return {
                ...state,
                showModal: false,
            }

        default:
            return state;
    }
}
