import { all, takeEvery, put, call } from 'redux-saga/effects';
import actions from './actions';
import { addData, getList, deleteData } from '../../helpers/fackBackend_Helper';

function* getTariffListResponse() {
    try {
        const response = yield call(getList, '/tariffs');
        if (response.response.Status) {
            yield put(
                actions.getTariffSuccess(
                    response.response.Data
                ));
        } else {
            yield put(actions.getTariffFailed());
        }
    } catch (error) {
        yield put(actions.getTariffFailed());
    }
}

function* addTariffDataResponse({ payload: { data } }) {
    try {
        const response = yield call(addData, '/createTariff', data);
        if (response.response.Status) {
            const responseList = yield call(getList, '/tariffs');
            if (responseList.response.Status) {
                yield put(
                    actions.addTariffSuccess(
                        response.response.Message, responseList.response.Data
                    ));
            } else {
                yield put(actions.addTariffFailed(
                    response.response.Message
                ));
            }
        } else {
            yield put(actions.addTariffFailed(response.response.Message));
        }
    } catch (error) {
        yield put(actions.addTariffFailed(error));
    }
}

function* getTariffMappedDataResponse({ payload: { tariffId } }) {
    try {
        const response = yield call(getList, '/tariffGroupMapping/' + tariffId);
        if (response.response.Status) {
            yield put(
                actions.getTariffDataByIdSuccessfull(
                    response.response.Data
                ));
        } else {
            yield put(actions.getTariffFailed());
        }
    } catch (error) {
        yield put(actions.getTariffFailed());
    }
}


function* editTariffDataResponse({ payload: { tariffId, data } }) {
    try {
        const response = yield call(addData, '/updateTariff/' + tariffId, data);
        if (response.response.Status) {
            const responseList = yield call(getList, '/tariffs');
            if (responseList.response.Status) {
                yield put(
                    actions.editTariffSuccess(
                        response.response.Message, responseList.response.Data
                    ));
            } else {
                yield put(actions.addTariffFailed(
                    response.response.Message
                ));
            }
        } else {
            yield put(actions.addTariffFailed(response.response.Message));
        }
    } catch (error) {
        yield put(actions.addTariffFailed(error));
    }
}

function* deleteTariffDataResponse({ payload: { tariffId, status } }) {
    try {
        const response = yield call(deleteData, '/deleteTariff/' + tariffId + '/' + status);
        if (response.response.Status) {
            const responseList = yield call(getList, '/tariffs');
            if (responseList.response.Status) {
                yield put(
                    actions.deleteTariffSuccess(
                        response.response.Message, responseList.response.Data
                    ));
            } else {
                yield put(actions.addTariffFailed(
                    response.response.Message
                ));
            }
        } else {
            yield put(actions.addTariffFailed(response.response.Message));
        }
    } catch (error) {
        yield put(actions.addTariffFailed(error));
    }
}

export default function* rootSaga() {
    yield all([takeEvery(actions.GET_TARIFF, getTariffListResponse)]);
    yield all([takeEvery(actions.ADD_TARIFF, addTariffDataResponse)]);
    yield all([takeEvery(actions.GET_TARIFF_DATA_BY_ID, getTariffMappedDataResponse)]);
    yield all([takeEvery(actions.EDIT_TARIFF, editTariffDataResponse)]);
    yield all([takeEvery(actions.DELETE_TARIFF, deleteTariffDataResponse)]);
}
