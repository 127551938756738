const actions = {
    GET_COUNTRY: 'GET_COUNTRY',
    GET_COUNTRY_SUCCESS: 'GET_COUNTRY_SUCCESS',
    GET_COUNTRY_FAILED: 'GET_COUNTRY_FAILED',
    getCountry: () => ({
      type: actions.GET_COUNTRY,
    }),
    getCountrySuccess: (countryResult) => ({
      type: actions.GET_COUNTRY_SUCCESS,
      countryResult
    }),
    getCountryFailed: (countryError) => ({
      type: actions.GET_COUNTRY_FAILED,
      countryError
    }),
  };
  export default actions;
  