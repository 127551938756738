const actions = {
    GET_BANNER_DATA: "GET_BANNER_DATA",
    GET_BANNER_DATA_SUCCESS: "GET_BANNER_DATA_SUCCESS",
    GET_BANNER_DATA_FAILED: "GET_BANNER_DATA_FAILED",
    ADD_BANNER: "ADD_BANNER",
    ADD_BANNER_SUCCESS: "ADD_BANNER_SUCCESS",
    ADD_BANNER_FAILED: "ADD_BANNER_FAILED",
    CHANGE_BANNER_STATUS: "CHANGE_BANNER_STATUS",
    CHANGE_BANNER_STATUS_SUCCESS: "CHANGE_BANNER_STATUS_SUCCESS",
    CHANGE_BANNER_STATUS_FAILED: "CHANGE_BANNER_STATUS_FAILED",
    DELETE_BANNER: "DELETE_BANNER",
    DELETE_BANNER_SUCCESS: "DELETE_BANNER_SUCCESS",
    DELETE_BANNER_FAILED: "DELETE_BANNER_FAILED",
    PREPARE_BANNER_FORM: "PREPARE_BANNER_FORM",
    RESET_BANNER: "RESET_BANNER",
    RESET_BANNER_MESSAGE: "RESET_BANNER_MESSAGE",

    getBanner: () => ({
        type: actions.GET_BANNER_DATA,
    }),
    getBannerSuccess: (bannerResult) => ({
        type: actions.GET_BANNER_DATA_SUCCESS,
        bannerResult
    }),
    getBannerFailed: (error) => ({
        type: actions.GET_BANNER_DATA_FAILED,
        error
    }),
    addBanner: (data) => ({
        type: actions.ADD_BANNER,
        payload: { data }
    }),
    addBannerSuccess: (message, bannerResult) => ({
        type: actions.ADD_BANNER_SUCCESS,
        message, bannerResult
    }),
    addBannerFailed: (error) => ({
        type: actions.ADD_BANNER_FAILED,
        error
    }),
    changeBannerStatus: (data) => ({
        type: actions.CHANGE_BANNER_STATUS,
        payload: { data }
    }),
    changeBannerStatusSuccess: (message, bannerResult) => ({
        type: actions.CHANGE_BANNER_STATUS_SUCCESS,
        message, bannerResult
    }),
    changeBannerStatusFailed: (error) => ({
        type: actions.CHANGE_BANNER_STATUS_FAILED,
        error
    }),
    deleteBanner: (data) => ({
        type: actions.DELETE_BANNER,
        payload: { data }
    }),
    deleteBannerSuccess: (message, bannerResult) => ({
        type: actions.DELETE_BANNER_SUCCESS,
        message, bannerResult
    }),
    deleteBannerFailed: (error) => ({
        type: actions.DELETE_BANNER_FAILED,
        error
    }),

    prepareBannerForm: () => ({
        type: actions.PREPARE_BANNER_FORM
    }),
    resetBanner: () => ({
        type: actions.RESET_BANNER
    }),
    resetBannerMessage: () => ({
        type: actions.RESET_BANNER_MESSAGE
    }),

}
export default actions;