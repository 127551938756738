import actions from "./actions";

const initState = {
    imei: [],
    loading: false,
    error: null,
    message: null,
    modal: false,
    imeiNumber : null
};

export default function reducer(state = initState, action) {
    switch (action.type) {
        case actions.CHECK_IMEI:
            return {
                ...state,
                loading: true,
            };
        case actions.CHECK_IMEI_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                message: null,
                imei: JSON.parse(action.data.Response),
                imeiNumber : action.data.IMEI,
                modal: true,
            };
        case actions.CHECK_IMEI_FAILED:
            return {
                ...state,
                loading: false,
                error: null,
            };

        case actions.CLOSE_MODAL:
            return {
                ...state,
                modal: false,
            };

        default:
            return state;
    }
}
