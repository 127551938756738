import { all, takeEvery, put, call } from 'redux-saga/effects';
import actions from './actions';
import { getList, addData, editData, deleteData } from '../../helpers/fackBackend_Helper';

function* addCouponResponse({ payload: { data } }) {
    try {
        const response = yield call(addData, '/addCoupon', data);
        if (response.Status) {
            yield put(actions.addCouponSuccess(response.Message, response.Message));
            // yield put(actions.getCoupon());
            const couponList = yield call(getList, '/getCoupon');
            if (couponList.Status) {
                yield put(actions.getCouponSuccess(couponList.Message, couponList.Coupons));
            } else {
                yield put(actions.getCouponFailed(couponList.Message));
            }
        } else {
            yield put(actions.addCouponFailed(response.Message));
            yield put(actions.resetCoupon());
        }
    } catch (error) {
        yield put(actions.addCouponFailed(error));
    }
}

function* getCouponResponse() {
    try {
        const response = yield call(getList, '/getCoupon');
        if (response.Status) {
            yield put(actions.getCouponSuccess(response.Message, response.Coupons));
        } else {
            yield put(actions.getCouponFailed(
                response.response.Message
            ));
        }
    } catch (error) {
        yield put(actions.getCouponFailed(error));
    }
}

function* deleteCouponResponse({ payload: { couponId, status } }) {
    try {
        const response = yield call(deleteData, '/updateCouponStatus/' + status + '/' + couponId);
        if (response.Status) {
            yield put(actions.deleteCouponSuccess(response.Message));
            yield put(actions.getCoupon());
        } else {
            yield put(actions.addCouponFailed( response.Message ));    
        }
    } catch (error) {
        yield put(actions.addCouponFailed(error));
    }
}

function* editCouponResponse({ payload: { data } }) {
    try {
        const response = yield call(addData, '/updateCoupon', data);
        if (response.Status) {
            const couponList = yield call(getList, '/getCoupon');
            if (couponList.Status) {
                yield put( actions.editCouponSuccess( response.Message, couponList.Coupons ) );
                yield put(actions.getCouponSuccess(couponList.Message, couponList.Coupons));
            } else {
                yield put(actions.getCouponFailed(couponList.Message));
            }
        } else {
            yield put(actions.addCouponFailed(
                response.Message
            ));
            yield put(actions.resetCoupon());
        }
    } catch (error) {
        yield put(actions.addCouponFailed(error));
    }
}

// function* applyCouponResponse({ payload: { data} }) {
//     try {
//         const response = yield call(addData, '/applyCoupon' ,data);
//         if (response.response[0].Status ) {
//             yield put(actions.applyCouponSuccess(response.response[0].Message,response.response));
//         } else {
//             yield put(actions.applyCouponFailed( response.response[0].Message ));    
//         }
//     } catch (error) {
//         yield put(actions.applyCouponFailed(error));
//     }
// }

export default function* rootSaga() {
    yield all([takeEvery(actions.ADD_COUPON, addCouponResponse)]);
    yield all([takeEvery(actions.GET_COUPON, getCouponResponse)]);
    yield all([takeEvery(actions.DELETE_COUPON, deleteCouponResponse)]);
    yield all([takeEvery(actions.EDIT_COUPON, editCouponResponse)]);
    // yield all([takeEvery(actions.COUPON_APPLY, applyCouponResponse)]);

}